import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/List';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import Section1 from '../Home/Section1';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import Dotwave from '../Common/Dotwave';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';

function SearchByImage(props) {
  const navigate = useNavigate();
  const { images, noImages, searchImage, setsearchImage } =
    useContext(UserContext);

  const imagesAbove95 = images.filter((image) => image.similarity > 98);
  const imagesBelow95 = images.filter((image) => image.similarity <= 98);

  const [currentPageBelow95, setCurrentPageBelow95] = useState(1);
  const imagesPerPage = 25;

  const indexOfLastImageBelow95 = currentPageBelow95 * imagesPerPage;
  const indexOfFirstImageBelow95 = indexOfLastImageBelow95 - imagesPerPage;
  const currentImagesBelow95 = imagesBelow95.slice(
    indexOfFirstImageBelow95,
    indexOfLastImageBelow95
  );

  const handlePageChangeBelow95 = (event, value) => {
    setCurrentPageBelow95(value);
  };
  const handleimage = (src) => {
    navigate(`/Image_detail/${src}`);
  };

  useEffect(() => {
    if (searchImage.length == 0) {
      navigate(`/photos`);
    }
  }, []);

  return (
    <>
      <Section1
        content='The best Premium photos shared by talented creators.'
        height={'300px'}
        slider={false}
        mainMenuHidden={false}
      ></Section1>
      {noImages ? (
        <Grid
          xs={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'50vh'}
          marginY={2}
        >
          <img
            src='/noimage.svg'
            style={{
              height: '350px',
              width: '350px',
            }}
          />
        </Grid>
      ) : (
        <>
          {images.length > 0 ? (
            <Grid container justifyContent={'center'}>
              {imagesAbove95.length <= 0 ? (
                ''
              ) : (
                <>
                  <Typography
                    variant='h5'
                    textAlign='center'
                    mt={4}
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      color: '#525252',
                      fontWeight: 'bold',
                      fontSize: '28px',
                    }}
                  >
                    Best Match
                  </Typography>
                  <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          padding:'40px'
        }}
        mt={2}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
  
            width: '99%',
          }}
        >
                    {imagesAbove95.map((d, i) => (
                      <Box
                      key={i}
                      sx={{
                        position: 'relative',
                        height: '200px',
                        flex: '1 1 auto',
                        maxWidth: '500px',
                        overflow: 'hidden',
                        minWidth: '200px',
                        cursor:'pointer',
                        marginBottom: 0,
                        borderRadius: 2,
                        transition: 'all 0.3s ease',
                        '&:hover .overlay': {
                          opacity: 1,
                        },
                        '&:hover .typography-container': {
                          marginTop: '150px',
                        },
                      }}
                      onClick={() => handleimage(d.img_id)}
                      >
                        <img
                          src={d.url}
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                        />

                        <Grid
                          className='overlay'
                          sx={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            zIndex: 0,
                            opacity: 0,
                            transition: 'all 0.3s ease',
                            borderRadius: '5px',
                          }}
                        >
                          <Grid
                            className='typography-container'
                            marginTop={'190px'}
                            sx={{
                              transition: 'margin-top 0.4s ease',
                            }}
                          >
                            <Tooltip
                              title={d.filename}
                              placement='top'
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: 'black',
                                    backgroundColor: '#e5ecfa',
                                    border: '1px solid black',
                                    fontSize: '0.7rem',
                                    '& .MuiTooltip-arrow': {
                                      color: 'black',
                                    },
                                    letterSpacing: '.5px',
                                    padding: 1,
                                  },
                                },
                              }}
                            >
                              <Typography
                                variant='p'
                                sx={{
                                  color: 'white',
                                  fontSize: '14px',
                                  marginLeft: '10px',
                                }}
                              >
                                {d.filename.slice(0, 10) + '...'}
                              </Typography>
                            </Tooltip>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              sx={{ marginTop: '5px' }}
                            >
                              <FavoriteBorderRoundedIcon
                                sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                              />
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'white',
                                  marginLeft: '3px',
                                  fontSize: '12px',
                                }}
                              >
                                {d.total_likes}
                              </Typography>
                              <DownloadIcon
                                sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                              />
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'white',
                                  marginLeft: '3px',
                                  fontSize: '12px',
                                }}
                              >
                                {d.total_download}
                              </Typography>
                              <VisibilityIcon
                                sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                              />
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'white',
                                  marginLeft: '3px',
                                  fontSize: '12px',
                                }}
                              >
                                {d.total_view}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                  </Box>
                </>
              )}

              {currentImagesBelow95.length > 0 ? (
                <>
                  <Typography
                    variant='h5'
                    textAlign='center'
                    mt={4}
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      color: '#525252',
                      fontWeight: 'bold',
                      fontSize: '28px',
                    }}
                  >
                    Similar Images
                  </Typography>
                   <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          padding:'40px'
        }}
        mt={2}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
  
            width: '99%',
          }}
        >
                    {currentImagesBelow95.map((d, i) => (
                      <Box
                      key={i}
                      sx={{
                        position: 'relative',
                        height: '200px',
                        flex: '1 1 auto',
                        maxWidth: '500px',
                        overflow: 'hidden',
                        minWidth: '200px',
                        cursor:'pointer',
                        marginBottom: 0,
                        borderRadius: 2,
                        transition: 'all 0.3s ease',
                        '&:hover .overlay': {
                          opacity: 1,
                        },
                        '&:hover .typography-container': {
                          marginTop: '150px',
                        },
                      }}
                      onClick={() => handleimage(d.img_id)}
                      >
                        <img
                          src={d.url}
                          style={{
                            height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    display: 'block',
                          }}
                        />

                        <Grid
                          className='overlay'
                          sx={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            zIndex: 0,
                            opacity: 0,
                            transition: 'all 0.3s ease',
                            borderRadius: '5px',
                          }}
                        >
                          <Grid
                            className='typography-container'
                            marginTop={'190px'}
                            sx={{
                              transition: 'margin-top 0.4s ease',
                            }}
                          >
                            <Tooltip
                              title={d.filename}
                              placement='top'
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: 'black',
                                    backgroundColor: '#e5ecfa',
                                    border: '1px solid black',
                                    fontSize: '0.7rem',
                                    '& .MuiTooltip-arrow': {
                                      color: 'black',
                                    },
                                    letterSpacing: '.5px',
                                    padding: 1,
                                  },
                                },
                              }}
                            >
                              <Typography
                                variant='p'
                                sx={{
                                  color: 'white',
                                  fontSize: '14px',
                                  marginLeft: '10px',
                                }}
                              >
                                {d.filename.slice(0, 10) + '...'}
                              </Typography>
                            </Tooltip>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              sx={{ marginTop: '5px' }}
                            >
                              <FavoriteBorderRoundedIcon
                                sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                              />
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'white',
                                  marginLeft: '3px',
                                  fontSize: '12px',
                                }}
                              >
                                {d.total_likes}
                              </Typography>
                              <DownloadIcon
                                sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                              />
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'white',
                                  marginLeft: '3px',
                                  fontSize: '12px',
                                }}
                              >
                                {d.total_download}
                              </Typography>
                              <VisibilityIcon
                                sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                              />
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'white',
                                  marginLeft: '3px',
                                  fontSize: '12px',
                                }}
                              >
                                {d.total_view}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                  </Box>
                </>
              ) : (
                <></>
              )}

              <Grid container justifyContent={'center'} mt={4} mb={2}>
                <Pagination
                  count={Math.ceil(imagesBelow95.length / imagesPerPage)}
                  page={currentPageBelow95}
                  onChange={handlePageChangeBelow95}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: 'rgba(0, 0, 0, 0.54)',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#e4ecfa !important',
                      color: 'rgba(0,0,0,0.54) !important',
                    },
                    '& .MuiPaginationItem-ellipsis': {
                      color: '#e4ecfa',
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              mt={5}
              mb={5}
            >
              <Dotwave></Dotwave>
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default SearchByImage;
