import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Section1 from '../Home/Section1';
import Option_photo from '../Photos/Option_photo';
import { Grid } from '@mui/material';
import Footer from '../Footer/Footer';
import { useParams } from 'react-router-dom';

function Photos(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid container>
      <Section1
        content='The Most Recent Marble and laminate Pictures'
        height={'400px'}
        slider={false}
        mainMenuHidden={false}
      ></Section1>
      <Option_photo></Option_photo>
    </Grid>
  );
}

export default Photos;
