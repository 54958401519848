import {
  UpdatecategoryPost,
  auth_delete,
  auth_get,
  auth_post,
  categoryPost,
  category_patch,
  delet,
  download_get,
  get,
  imagePost,
  multi_Upload_post,
  patch,
  post,
} from './Request';
import {
  ADD_CATEGORY,
  ADD_USER,
  ALL_IMAGES,
  ALL_USER,
  CATEGORY,
  CHANGE_PASSWORD,
  DELETE_BY_ID,
  DELETE_CATEGORY,
  DELETE_USER,
  DOWNLOAD,
  IMG_DETAIL,
  LATEST_IMAGES,
  LIKE,
  LOGIN,
  MOST_DOWNLOAD,
  SEARCH,
  SEARCH_BY_TEXT,
  UPDATE_CATEGORY,
  UPDATE_HEIGHT_WIDTH,
  UPDATE_IMAGE_CATEGORY,
  UPDATE_USER_DETAIL,
  UPLOAD_IMAGE,
} from './endpoint';
import Swal from 'sweetalert2';
import '../css/global.css';

export function Download(userData) {
  return new Promise((resolve, reject) => {
    download_get(
      DOWNLOAD + '?id=' + userData,
      (response) => {
        resolve(response);
        show_tost('success', 'Download Successfully');
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function Category_type(activePage) {
  return new Promise((resolve, reject) => {
    get(
      CATEGORY,
      (response) => {
        resolve(response);
      },
      (error) => {
        show_tost('error', 'Unable to retrieve image details.');

        reject(error);
      }
    );
  });
}

export function AllImages(activePage, current) {
  return new Promise((resolve, reject) => {
    let endpoint;
    if (current == 'All') {
      endpoint = ALL_IMAGES;
    } else if (current == 'Latest') {
      endpoint = LATEST_IMAGES;
    } else {
      endpoint = MOST_DOWNLOAD;
    }
    get(
      endpoint + '?page=' + activePage,
      (response) => {
        resolve(response);
      },
      (error) => {
        show_tost('error', 'Unable to retrieve image details.');

        reject(error);
      }
    );
  });
}

export function imageDetails(filename) {
  return new Promise((resolve, reject) => {
    let token = sessionStorage.getItem('token');
    if (token) {
      auth_get(
        IMG_DETAIL + '?id=' + filename,
        (response) => {
          resolve(response);
        },
        (error) => {
          console.error('Error during sign-up:', error);

          if (error.response.status === 401 || error.response.status === 422) {
            sessionStorage.clear();
            sessionStorage.setItem('authError', 'Please log in.');
            window.location.href = '/signin';
          } else {
            show_tost('error', 'Unable to retrieve image details.');
          }
          reject(error);
        }
      );
    } else {
      get(
        IMG_DETAIL + '?id=' + filename,
        (response) => {
          resolve(response);
        },
        (error) => {
          if (
            error.response.request.status === 401 ||
            error.response.request.status === 422
          ) {
            show_tost('error', 'Please log in.');
          } else {
            show_tost('error', 'Unable to retrieve image details.');
          }
          reject(error);
        }
      );
    }
  });
}

export function all_user() {
  return new Promise((resolve, reject) => {
    auth_get(
      ALL_USER,
      (response) => {
        resolve(response);
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}
export function delete_category_id(id) {
  return new Promise((resolve, reject) => {
    delet(
      DELETE_CATEGORY + id,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        show_tost('error', error.response.data.error);
        reject(error);
      }
    );
  });
}

export function Delete_by_id(id) {
  return new Promise((resolve, reject) => {
    delet(
      DELETE_BY_ID + id,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        show_tost('error', error.response.data.error);
        reject(error);
      }
    );
  });
}
export function delete_user(id) {
  return new Promise((resolve, reject) => {
    auth_delete(
      DELETE_USER + '?user_id=' + id,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function Like_detail(filename, userData) {
  return new Promise((resolve, reject) => {
    auth_post(
      LIKE + '?id=' + filename,
      userData,
      (response) => {
        resolve(response);
        if (userData.flag === 1) {
          show_tost('success', 'Like Successfully');
        } else {
          show_tost('success', 'Unlike Successfully');
        }
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function add_user(userData) {
  return new Promise((resolve, reject) => {
    auth_post(
      ADD_USER,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', 'User Added Successfully');
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function update_user(userData, id) {
  return new Promise((resolve, reject) => {
    auth_post(
      UPDATE_USER_DETAIL + id,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function add_category(userData) {
  return new Promise((resolve, reject) => {
    categoryPost(
      ADD_CATEGORY,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', 'Category Added Successfully');
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function update_category(userData, id) {
  return new Promise((resolve, reject) => {
    UpdatecategoryPost(
      UPDATE_IMAGE_CATEGORY + id,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function change_password(userData, id) {
  return new Promise((resolve, reject) => {
    auth_post(
      CHANGE_PASSWORD + id,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', 'Password Changed Successfully.');
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function heightWidthChange(userData, id) {
  return new Promise((resolve, reject) => {
    patch(
      UPDATE_HEIGHT_WIDTH + id,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function edit_category(userData, id) {
  return new Promise((resolve, reject) => {
    category_patch(
      UPDATE_CATEGORY + id,
      userData,
      (response) => {
        resolve(response);
        show_tost('success', response.msg);
      },
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          sessionStorage.setItem('authError', 'Please log in.');
          window.location.href = '/signin';
        } else {
          show_tost('error', error.response.data.error);
        }
        reject(error);
      }
    );
  });
}

export function Search_by_text(userData, activePage) {
  return new Promise((resolve, reject) => {
    post(
      SEARCH_BY_TEXT + 'page=' + activePage,
      userData,
      (response) => {
        resolve(response);
      },
      (error) => {
        show_tost('error', 'Unable to retrieve image details.');
        reject(error);
      }
    );
  });
}

export function categoryTypeGet(activePage, category_name) {
  return new Promise((resolve, reject) => {
    let url = ALL_IMAGES + '?page=' + activePage;
    if (category_name !== 'ALL') {
      url += '&category=' + category_name;
    }
    get(
      url,
      (response) => {
        resolve(response);
      },
      (error) => {
        show_tost('error', 'Unable to retrieve image details.');
        reject(error);
      }
    );
  });
}

export function login(userData) {
  return new Promise((resolve, reject) => {
    post(
      LOGIN,
      userData,
      (response) => {
        sessionStorage.setItem('name', response.username);
        sessionStorage.setItem('token', response.access_token);
        sessionStorage.setItem('role', response.role);
        resolve(response);
        show_tost('success', 'Login Successfully.');
      },
      (error) => {
        show_tost('error', error.response.data.error);
        reject(error);
      }
    );
  });
}

export function imageSearch(file) {
  return new Promise((resolve, reject) => {
    imagePost(
      SEARCH,
      file,
      (response) => {
        resolve(response);
      },
      (error) => {
        show_tost('error', 'Unable to retrieve image details.');
        reject(error);
      }
    );
  });
}

export async function Upload_image_multi(
  file,
  setProgress,
  setTotalDone,
  setNotUpload,
  simpleupload
) {
  const totalFiles = file.files.length;
  let failedUploads = [];
  if (simpleupload == true) {
    for (const [index, elem] of file.files.entries()) {
      let progress = ((index + 1) / totalFiles) * 100;

      setProgress(progress.toFixed(0));
      setTotalDone(index + 1);

      try {
        await new Promise((resolve, reject) => {
          multi_Upload_post(
            UPLOAD_IMAGE,
            {
              category: file.category,
              files: [elem.file],
            },
            (response) => {
              resolve(response);
              show_tost('success', response.msg);
            },
            (error) => {
              failedUploads.push(elem);
              reject(error);
            }
          );
        });
      } catch (error) {
        console.error(`Error uploading file ${index + 1}:`, error);
      }
    }
  } else {
    for (const [index, elem] of file.files.entries()) {
      let progress = ((index + 1) / totalFiles) * 100;

      setProgress(progress.toFixed(0));
      setTotalDone(index + 1);

      try {
        await new Promise((resolve, reject) => {
          multi_Upload_post(
            UPLOAD_IMAGE,
            {
              category: file.category,
              files: [elem.file],
            },
            (response) => {
              show_tost('success', response.msg);
              resolve(response);
            },
            (error) => {
              failedUploads.push(elem);

              show_tost('error', error.response.data.error);
              reject(error);
            }
          );
        });
      } catch (error) {
        console.error(`Error uploading file ${index + 1}:`, error);
      }
    }
  }

  setNotUpload((prevFailedUploads) => [...prevFailedUploads, ...failedUploads]);
}

export function show_tost(type, msg) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    customClass: {
      container: 'custom-toast-height',
    },
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  Toast.fire({
    icon: type,
    title: msg,
  });
}
