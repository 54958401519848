import React from 'react';

const Uploadbtn = ({ onClickButton2, labelButton2 }) => {
  return (
    <div>
      <button
        variant='contained'
        onClick={onClickButton2}
        style={{
          color: '#e4ecfa',
          backgroundColor: 'rgba(0,0,0,0.54)',
          cursor: 'pointer',
          display: ' inline-block',
          fontFamily:
            'CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif',
          padding: '7px 20px',
          textAlign: 'center',
          transition: 'all 250ms',
          border: 0,
          fontSize: '16px',
          userSelect: 'none',
          borderRadius: '100px',
          touchAction: 'manipulation',
          minWidth: 'max-content',
        }}
      >
        {labelButton2}
      </button>
    </div>
  );
};

export default Uploadbtn;
