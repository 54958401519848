// Sign Up
export const SIGN_UP = '//sign_up';

//Log In
export const LOGIN = '/api/login';

//Search
export const SEARCH = '/api/search';

//Latest Images
export const LATEST_IMAGES = '/api/latest';

//All Images
//Latest Images
export const ALL_IMAGES = '/api/photos';

//Most Download Images
export const MOST_DOWNLOAD = '/api/most_downloads';

//Get Users
export const GET_USERS = '/api/get_user';

//Upload Image
export const UPLOAD_IMAGE = '/api/upload';

//Category Type
export const CATEGORY = '/api/category';

//Image Detail
export const IMG_DETAIL = '/api/img_detail';

//Download
export const DOWNLOAD = '/api/download';

//Search By Text
export const SEARCH_BY_TEXT = '/api/search_by_text?';

//like
export const LIKE = '/api/likes';

//add User
export const ADD_USER = '/api/add_user';

//Delete user
export const DELETE_USER = '/api/delete_user';

//all User
export const ALL_USER = '/api/users';

//change password
export const CHANGE_PASSWORD = '/api/change_password/';

//Update height,width
export const UPDATE_HEIGHT_WIDTH = '/api/update_image/';

//Delete Images
export const DELETE_BY_ID = '/api/delete_image/';

//Update User Detail
export const UPDATE_USER_DETAIL = '/api/update_user_detail/';

//Add Category
export const ADD_CATEGORY = '/api/add_category';

//Delete Category
export const DELETE_CATEGORY = '/api/delete_category/';

//Update Category
export const UPDATE_CATEGORY = '/api/update_category/';

//Update Image Category
export const UPDATE_IMAGE_CATEGORY = '/api/update_image_category/';
