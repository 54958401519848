import React from 'react';
import Button from '@mui/material/Button';

const ButtonBorder = ({ onClickButton1, labelButton1 }) => {
  return (
    <div>
      <button
        class='button-33'
        role='button'
        onClick={onClickButton1}
        style={{ marginRight: '5px', fontWeight: '500' }}
      >
        {labelButton1}
      </button>
    </div>
  );
};

export default ButtonBorder;
