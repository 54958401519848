import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ButtonBorder from '../Common/ButtonBorder';
import { useNavigate } from 'react-router-dom';
import { AllImages } from '../../Utils/Helper';
import Dotwave from '../Common/Dotwave';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { UserContext } from '../Context/List';
import Tooltip from '@mui/material/Tooltip';

const Section2 = () => {
  const navigate = useNavigate();
  const [images, setimages] = useState([]);
  const [loading, setloading] = useState(false);
  const activePage = 1;
  const { category_detail, setallImages } = useContext(UserContext);

  let margintop;

  if (category_detail.length > 2) {
    if (category_detail.length == 1 && category_detail[0].category == 'ALL') {
      margintop = '30px';
    } else {
      margintop = '150px';
    }
  } else {
    margintop = '30px';
  }

  useEffect(() => {
    setloading(true);
    AllImages(activePage)
      .then((data) => {
        setimages(data.images);
        setallImages(data.images);
        setloading(false);
      })
      .catch((error) => {
        console.error('Rejected:', error);
        setloading(false);
      });
  }, [activePage]);

  const handleimage = (src) => {
    navigate(`/Image_detail/${src}`);
  };

  const handleViewMore = () => {
    navigate('/photos');
  };

  return (
    <Grid container spacing={2} mb={2} mt={margintop} textAlign={'center'}>
      <Grid item xs={12} textAlign={'center'}>
        <Typography
          variant='h6'
          sx={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: {
              xs: '24px',
              sm: '26px',
              md: '28px',
              lg: '30px',
            },
            width: {
              xs: '95%',
              sm: '80%',
              md: '70%',
              lg: '50%',
            },
            padding: '10px 10px',
            margin: '0 auto',
          }}
        >
          The most recent designs for marble, tiles, laminate and vectors
        </Typography>
        <Typography variant='body1' mt={1} sx={{ color: 'black' }}>
          Discover the newest designs for marble, tiles, laminate pictures, and
          vectors.
        </Typography>
      </Grid>
      {loading ? (
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={5}
          mb={2}
        >
          <Dotwave></Dotwave>
        </Grid>
      ) : (
        <>
          {images.length === 0 ? (
            <Grid
              xs={12}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={'50vh'}
              marginY={2}
            >
              <img
                src='/noimage.svg'
                style={{
                  height: '350px',
                  width: '350px',
                }}
              />
            </Grid>
          ) : (
            <>
              <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        padding:'40px'
      }}
      mt={2}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '4px',

          width: '99%',
        }}
      >
        {images.slice(0, 16).map((d, i) => (
          <Box
            key={i}
            sx={{
              position: 'relative',
              height: '200px',
              flex: '1 1 auto',
              maxWidth: '500px',
              overflow: 'hidden',
              minWidth: '200px',
              cursor:'pointer',
              marginBottom: 0,
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover .overlay': {
                opacity: 1,
              },
              '&:hover .typography-container': {
                marginTop: '150px',
              },
            }}
            onClick={() => handleimage(d.img_id)}
          >
            <img
              src={d.image_url}
              alt={d.title}
              style={{
                height: '100%',

                width: '100%',
                objectFit: 'cover',
                display: 'block',
              }}
            />
            <Grid
                        className='overlay'
                        sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          height: '100%',
                          width: '100%',
                          backgroundColor: 'rgba(0,0,0,0.4)',
                          zIndex: 0,
                          opacity: 0,
                          transition: 'all 0.3s ease',
                          borderRadius: '5px',
                        }}
                      >
                       
                        <Grid
                          className='typography-container'
                          marginTop={'190px'}
                          sx={{
                            transition: 'margin-top 0.4s ease',
                            display:'flex',
                            justifyContent:"start",
                            flexDirection:"column",
                            alignItems:"flex-start"
                          }}
                        >
                          <Tooltip
                            title={d.filename}
                            placement='top'
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: 'black',
                                  backgroundColor: '#e5ecfa',
                                  border: '1px solid black',
                                  fontSize: '0.7rem',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                  letterSpacing: '.5px',
                                  padding: 1, 
                                     
                                },
                              },
                            }}
                          >
                            <Typography
                              variant='p'
                              sx={{
                                color: 'white',
                                fontSize: '14px',
                               
                                marginLeft:"10px"
                              }}
                            >
                              {d.filename.slice(0, 10) + '...'}
                            </Typography>
                          </Tooltip>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            sx={{ marginTop: '5px' }}
                          >
                            <FavoriteBorderRoundedIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_likes}
                            </Typography>
                            <DownloadIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_download}
                            </Typography>
                            <VisibilityIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_view}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
          </Box>
        ))}
      </Box>
    </Box>
              {/* <Grid
                container
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                mt={2}
                // sx={{ backgroundColor: 'blue' }}
              >
                {images.slice(0, 16).map((d, i) => (
                  <>
                    <Grid
                      item
                      height={200}
                      width={200}
                      m={2}
                      spacing={2}
                      onClick={() => handleimage(d.img_id)}
                      sx={{
                        borderRadius: '5px',
                        position: 'relative',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover .overlay': {
                          opacity: 1,
                        },
                        '&:hover .typography-container': {
                          marginTop: '150px',
                        },
                      }}
                    >
                      <img
                        src={d.image_url}
                        style={{
                          height: 'inherit',
                          width: '200px',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          borderRadius: '5px',
                        }}
                      />

                      <Grid
                        className='overlay'
                        sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          height: 'inherit',
                          width: 'inherit',
                          backgroundColor: 'rgba(0,0,0,0.4)',
                          zIndex: 0,
                          opacity: 0,
                          transition: 'all 0.3s ease',
                          borderRadius: '5px',
                        }}
                      >
                        <Grid
                          className='typography-container'
                          display={'flex'}
                          justifyContent={'flex-start'}
                          alignItems={'flex-start'}
                          flexDirection={'column'}
                          marginTop={'190px'}
                          sx={{
                            transition: 'margin-top 0.4s ease',
                          }}
                        >
                          <Tooltip
                            title={d.filename}
                            placement='top'
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: 'black',
                                  backgroundColor: '#e5ecfa',
                                  border: '1px solid black',
                                  fontSize: '0.7rem',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                  letterSpacing: '.5px',
                                  padding: 1,
                                },
                              },
                            }}
                          >
                            <Typography
                              variant='p'
                              sx={{
                                color: 'white',
                                fontSize: '14px',
                                marginLeft: '10px',
                              }}
                            >
                              {d.filename.slice(0, 10) + '...'}
                            </Typography>
                          </Tooltip>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            sx={{ marginTop: '5px' }}
                          >
                            <FavoriteBorderRoundedIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_likes}
                            </Typography>
                            <DownloadIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_download}
                            </Typography>
                            <VisibilityIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_view}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Grid> */}
              <Grid item xs={12} mb={2}>
                <ButtonBorder
                  onClickButton1={handleViewMore}
                  labelButton1='View More'
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default Section2;
