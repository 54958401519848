import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Link,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Section1 from '../Home/Section1';
import { login, show_tost } from '../../Utils/Helper';
import { UserContext } from '../Context/List';
import {
  LockOutlined as LockOutlinedIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import '../../css/global.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setRole, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const authError = sessionStorage.getItem('authError');
    if (authError) {
      show_tost('error', authError);
      sessionStorage.removeItem('authError');
    }
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem('token');
    const role = sessionStorage.getItem('role');
    const name = sessionStorage.getItem('name');

    const userData = { username: email, password };
    login(userData)
      .then((data) => {
        setUser({ username: name, access_token: token });
        setRole(role);
        navigate('/');
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  };

  return (
    <>
      <Section1
        content=''
        height='100px'
        inputhide='true'
        slider={false}
        mainMenuHidden={true}
      ></Section1>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ marginY: '20px' }}
      >
        <Grid
          sx={{
            width: '100%',
            height: '70vh',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            height={'400px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            component={Paper}
            elevation={4}
            // marginY={3}
            sx={{
              width: { xl: '50%', lg: '65%', md: '75%', sm: '70%', xs: '90%' },
              // backgroundColor: 'blue',
            }}
          >
            <Grid
              item
              md={6}
              sx={{
                display: { xs: 'none', md: 'block', borderRadius: '5px' },
              }}
            >
              <img
                src='./bg1.png'
                style={{
                  height: '400px',
                  width: '100%',
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={6}
              sx={{
                height: '400px',
                width: { lg: '100%', sm: '80%' },
              }}
            >
              <Grid
                p={2}
                style={{
                  height: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar style={{ backgroundColor: '#e4ecfa' }}>
                  <LockOutlinedIcon sx={{ color: '#150f0fc2' }} />
                </Avatar>
                <Typography
                  component='h1'
                  variant='h5'
                  sx={{ color: '#150f0fc2', fontFamily: 'sans-serif' }}
                >
                  Sign in
                </Typography>
                <form
                  style={{ width: '100%', marginTop: '8px' }}
                  onSubmit={handleLogin}
                >
                  <TextField
                    className='inputcolorhide'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Username'
                    name='email'
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete='on'
                    sx={{
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      '& > :not(style)': {},
                    }}
                  />
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete='off'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge='end'
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      '& > :not(style)': {},
                    }}
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    sx={{
                      marginTop: '16px',
                      backgroundColor: '#e4ecfa', // Light blue color
                      backgroundImage:
                        'linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                      color: '#150f0fc2', // Dark color for text
                      padding: '7px 20px',
                      fontSize: '14px',
                      fontFamily: 'sans-serif',
                      transition: 'all 250ms',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: '#e4ecfa', // Ensure the background color stays the same on hover
                      },
                    }}
                  >
                    sign in
                  </Button>
                  {/* <Grid container style={{ marginTop: '20px' }}>
                    <Grid item xs>
                      <Link href='#' variant='body2'>
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href='/SignUpPage' variant='body2'>
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid> */}
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
