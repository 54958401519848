import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import {
  Grid,
  Input,
  Box,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
  Paper,
  MenuList,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Navbar from '../Navbar/Navbar';
import Modal from '@mui/material/Modal';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Category_type, imageSearch, show_tost } from '../../Utils/Helper';
import Img_slider from './Img_slider';
import { UserContext } from '../Context/List';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import '../../css/global.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Themebtn from '../Common/Themebtn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { sm: 400, xs: 300 },
  bgcolor: '#e4ecfa',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const Section1 = ({ content, height, inputhide, slider, mainMenuHidden }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const {
    setImages,
    setinputlabel,
    inputlabel,
    setdropdownselest,
    dropdownselest,
    listCategory,
    setnoImages,
    category_detail,
    infinity,
    setinfinity,
    setsearchImage,
    searchList,
  } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    Category_type()
      .then((data) => {
        const allCategory = {
          category: 'ALL',
        };
        const updatedCategories = [allCategory, ...data];
        setCategories(updatedCategories);
        setCategory(data);
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileURL(URL.createObjectURL(selectedFile));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchText();
    }
  };
  useEffect(() => {
    if (!open) {
      setFile(null);
      setFileURL('');
    }
  }, [open]);

  const handleSearchImage = async (e) => {
    e.preventDefault();
    if (!file) {
      show_tost('error', 'Please select a file first!');
      return;
    }
    if (!['image/png', 'image/jpeg'].includes(file.type)) {
      show_tost('error', 'Unsupported File type!');
      setOpen(false);
      return;
    }
    setImages([]);
    setLoading(true);
    imageSearch(file)
      .then((data) => {
        const imagesWithSimilarity = data.result.map((e) => ({
          url: e.Image,
          similarity: e.similarity,
          total_download: e.total_download,
          total_likes: e.total_likes,
          total_view: e.total_view,
          img_id: e.img_id,
          filename: e.filename,
        }));
        if (imagesWithSimilarity.length == 0) {
          setImages(imagesWithSimilarity);
          setnoImages(true);
        } else {
          setnoImages(false);
          setImages(imagesWithSimilarity);
        }
        setsearchImage([file]);
        setLoading(false);
        navigate('/SearchByImage');
      })
      .catch((error) => {
        setLoading(false);
        console.error('Rejected:', error);
        navigate('/signin');
      });
    handleClose();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setinputlabel(value);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      show_tost('error', 'Please select one image.');
    } else {
      const selectedFile = files[0];
      setFile(selectedFile);
      setFileURL(URL.createObjectURL(selectedFile));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    window.location.pathname.includes('Category')
      ? decodeURIComponent(
          window.location.pathname.split('/')[
            window.location.pathname.split('/').length - 1
          ]
        )
      : 'All'
  );

  const isMenuOpen = Boolean(anchorEl);

  const handleOpen1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setdropdownselest(option);
    setSelectedOption(option);
    if (inputlabel == null) {
      navigate(`/photos/${option}`);
    } else {
      navigate(`/photos/${option}/${inputlabel}`);
    }
    handleClose1();
  };

  const handleSearchText = (e) => {
    if (inputlabel == null) {
      navigate(`/photos/${dropdownselest}`);
    } else {
      navigate(`/photos/${dropdownselest}/${inputlabel}`);
    }
    setinfinity(!infinity);
  };

  const convertBytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

  return (
    <>
      <Backdrop
        sx={{
          color: '#e5edfa',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress className='homeloading' color='inherit' />
      </Backdrop>
      <AppBar position='relative'>
        <Navbar menuHidden={mainMenuHidden}></Navbar>
        <Grid
          container
          position={'relative'}
          justifyContent={'center'}
          alignItems='center'
        >
          <Grid
            item
            position={'relative'}
            sx={{
              background: 'url(/bg1.png)',
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: height,
              width: '100%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Grid
              sx={{
                height: 'inherit',
                background: 'rgba(0, 0, 0, 0.6)',
                width: 'inherit',
              }}
              item
              position={'absolute'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              top={0}
              left={0}
            >
              <Grid
                component='section'
                textAlign={'center'}
                sx={{
                  width: '90%',
                  padding: '20px 20px',
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    color: 'whitesmoke',
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                    fontSize: {
                      xs: '17px',
                      sm: '19px',
                      md: '22px',
                      lg: '25px',
                    },
                    width: '80%',
                    padding: '10px 10px',
                    margin: '0 auto',
                  }}
                >
                  {content}
                </Typography>
                {inputhide ? (
                  ''
                ) : (
                  <Grid
                    item
                    mt={2}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.25)',
                        borderRadius: '5px',
                        padding: '10px 10px',
                        position: 'relative',
                      }}
                    >
                      <Box
                        onClick={handleOpen1}
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{
                          backgroundColor: 'white',
                          height: '40px',
                          width: '50%',
                          borderTopLeftRadius: '5px',
                          borderBottomLeftRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography
                          display={'flex'}
                          alignItems={'center'}
                          sx={{
                            width: '80%',
                            color: 'rgba(0, 0, 0, 0.54)',
                            fontSize: '13px',
                            backgroundColor: 'white',
                            height: '40px',
                            marginLeft: '10px',
                          }}
                        >
                          {dropdownselest}
                        </Typography>
                        <KeyboardArrowDownIcon
                          sx={{
                            color: 'rgba(0, 0, 0, 0.54)',
                            width: '20%',
                            transform: isMenuOpen
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.2s ease-in-out',
                          }}
                        ></KeyboardArrowDownIcon>
                      </Box>
                      {listCategory.length > 0 ? (
                        <Menu
                          anchorEl={anchorEl}
                          open={isMenuOpen}
                          onClose={handleClose1}
                          sx={{
                            mt: 1,
                            '& .MuiMenu-paper': {
                              height: 'auto',
                              maxHeight: '200px',
                              width: '200px', // Ensure the menu paper also respects max height
                              overflowY: 'auto', // Enable vertical scrolling
                              '&.custom-scrollbar': {
                                '&::-webkit-scrollbar': {
                                  width: '8px', // Adjust scrollbar width if needed
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  background: '#888',
                                  borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: '#555',
                                },
                              },
                            },
                          }}
                          PaperProps={{
                            className: 'custom-scrollbar',
                          }}
                        >
                          {dropdownselest == null
                            ? categories.map((d, i) => (
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(d.category)
                                  }
                                  sx={{
                                    backgroundColor: 'white !important',
                                    '&:hover': {
                                      backgroundColor: 'lightgray !important',
                                    },
                                  }}
                                >
                                  {d.category}
                                </MenuItem>
                              ))
                            : categories.map((d, i) =>
                                dropdownselest == d.category ? (
                                  <MenuItem
                                    onClick={() =>
                                      handleMenuItemClick(d.category)
                                    }
                                    sx={{
                                      backgroundColor: 'lightgray',
                                      borderLeft: '4px solid #ff0000',
                                      '&:hover': {
                                        backgroundColor: 'lightgray !important',
                                      },
                                    }}
                                  >
                                    {d.category}
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() =>
                                      handleMenuItemClick(d.category)
                                    }
                                    sx={{
                                      backgroundColor: 'white !important',
                                      '&:hover': {
                                        backgroundColor: 'lightgray !important',
                                      },
                                    }}
                                  >
                                    {d.category}
                                  </MenuItem>
                                )
                              )}
                        </Menu>
                      ) : (
                        <></>
                      )}

                      <Input
                        id='search'
                        placeholder={inputlabel == null ? 'Search' : inputlabel}
                        disableUnderline
                        value={inputlabel}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        sx={{
                          width: { md: '400px', sm: '350px', xs: '300px' },
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          padding: '0px 10px 0px 20px',
                          backgroundColor: 'white',
                          '& input': {
                            border: 'none',
                          },
                        }}
                        endAdornment={
                          <>
                            <IconButton onClick={handleSearchText}>
                              <SearchIcon />
                            </IconButton>
                            <Tooltip title='Search By Image'>
                              <IconButton onClick={handleOpen}>
                                <AddPhotoAlternateIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {category.length > 2 ? slider ? <Img_slider /> : '' : ''}
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Grid container justifyContent='flex-end'>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 5, right: 5 }}
              >
                <CloseIcon sx={{ color: '#000' }} />
              </IconButton>
            </Grid>
            <Grid container justifyContent='center' textAlign='center'>
              <Typography
                id='modal-modal-title'
                variant='h5'
                component='h2'
                gutterBottom
              >
                Upload Image
              </Typography>

              {file !== null ? (
                <Box
                  sx={{
                    p: 2,
                    border: '2px dashed rgb(24, 44, 97)',
                    borderRadius: 1,
                    mt: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => document.getElementById('file-upload').click()}
                >
                  <Grid
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <img
                      src={fileURL}
                      alt='Selected File'
                      style={{
                        height: '100px',
                        width: '100px',
                        objectFit: 'cover',
                        marginBottom: '10px',
                        borderRadius: '5px',
                      }}
                    />
                    <Typography variant='body1' sx={{ minWidth: '180px' }}>
                      File Name: {file.name}
                    </Typography>
                    <Typography variant='body2'>
                      File Size: {convertBytesToMB(file.size)} MB
                    </Typography>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 2,
                    border: '2px dashed rgb(24, 44, 97)',
                    borderRadius: 1,
                    mt: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => document.getElementById('file-upload').click()}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <CloudUploadIcon
                    sx={{ color: 'rgb(24, 44, 97)', fontSize: 40 }}
                  />
                  <Typography
                    sx={{
                      mt: 2,
                      color: 'rgb(24, 44, 97)',
                      fontWeight: 'bold',
                    }}
                  >
                    Browse File To Upload
                  </Typography>
                  <input
                    id='file-upload'
                    type='file'
                    accept='image/*'
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Box>
              )}
              <Box mt={3}>
                {/* <Uploadbtn
                  labelButton2='Search Image'
                  onClickButton2={handleSearchImage}
                /> */}
                <Themebtn label='Search Image' onClick={handleSearchImage} />
              </Box>
            </Grid>
          </Box>
        </Modal>
      </AppBar>
    </>
  );
};

export default Section1;
