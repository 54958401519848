import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Category_type, categoryTypeGet } from '../../Utils/Helper';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Context/List';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../css/global.css';

const ImgSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [category, setcategory] = useState([]);
  const [category_name, setcategory_name] = useState('');
  const navigate = useNavigate();
  const { setcategory_detail } = useContext(UserContext);

  useEffect(() => {
    Category_type()
      .then((data) => {
        setcategory(data);
        setcategory_detail(data);
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  }, [setcategory_detail]);

  const handleCategory = (e) => {
    setcategory_name(e);
    navigate(`/photos/${e}`);
  };

  const [slide, setslide] = React.useState(
    window.innerWidth < 1080 ? (window.innerWidth < 720 ? 1 : 3) : 5
  );
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(() => {
    const handleResize = () => {
      setslide(
        window.innerWidth < 1080 ? (window.innerWidth < 720 ? 1 : 3) : 4
      );
    };
    handleResize(); // Set initial slide value based on current window size

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [category]);

  const swiperRef = useRef(null);

  if (category.length <= 2) {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.stop();
    }
  } else {
    swiperRef.current.autoplay.start();
  }

  let appendNumber = 4;
  let prependNumber = 1;

  const prepend2 = () => {
    swiperRef.prependSlide([
      '<div class="swiper-slide">Slide ' + --prependNumber + '</div>',
      '<div class="swiper-slide">Slide ' + --prependNumber + '</div>',
    ]);
  };

  const prepend = () => {
    swiperRef.prependSlide(
      '<div class="swiper-slide">Slide ' + --prependNumber + '</div>'
    );
  };

  const append = () => {
    swiperRef.appendSlide(
      '<div class="swiper-slide">Slide ' + ++appendNumber + '</div>'
    );
  };

  const append2 = () => {
    swiperRef.appendSlide([
      '<div class="swiper-slide">Slide ' + ++appendNumber + '</div>',
      '<div class="swiper-slide">Slide ' + ++appendNumber + '</div>',
    ]);
  };

  return (
    <Grid
      sx={{
        position: 'absolute',
        top: '80%',
        left: { xl: '18%', lg: '18%', md: '25%', xs: '10%' },
        width: { xl: '80%', lg: '80%', md: '70%', xs: '90%' },
      }}
    >
      <Grid
        container
        sx={{
          width: 'inherit',
          overflow: 'hidden',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Swiper
          slidesPerView={slide}
          centeredSlides={false}
          spaceBetween={30}
          pagination={{
            type: 'fraction',
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          autoplay={{ delay: 2500, disableOnInteraction: 'false' }}
          navigation={category.length <= 2 ? false : true}
          modules={
            category.length <= 2
              ? [Navigation, Autoplay]
              : [Pagination, Autoplay]
          }
          className='mySwiper'
        >
          {category.map((image, index) => (
            <SwiperSlide
              onClick={() => {
                handleCategory(image.category);
              }}
            >
              <img
                src={image.category_img}
                alt='Image 1'
                className='gallery-image'
              />
              <div className='image-overlay'>
                <div className='image-overlay-inside'>
                  <span
                    className='image-text'
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    {image.category}
                  </span>
                  <span
                    className='image-text'
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    {image.total_img}
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default ImgSlider;
