import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Themebtn = ({ label, onClick }) => {
  return (
    <Grid>
      <button
        onClick={onClick}
        style={{
          padding: '7px 20px',
          // backgroundColor: 'rgba(0, 0, 0, 0.54)',
          border: 'none',
          borderRadius: '5px',
          // marginLeft: '20px',
          fontSize: '14px',
          // margin: '10px 10px 0 20px',
          cursor: 'pointer',
          // backgroundColor: 'rgb(24, 44, 97)' : 'rgb(228, 236, 250)',
          // color: active ? 'whitesmoke' : 'rgba(0, 0, 0, 0.54)',

          backgroundColor: 'rgb(24, 44, 97)',
          color: 'whitesmoke',
        }}
      >
        {label}
      </button>
    </Grid>
  );
};

export default Themebtn;
