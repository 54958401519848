import React from 'react';
import { Box, keyframes } from '@mui/material';
import { styled } from '@mui/system';

const waveAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const Dot = styled(Box)(({ delay }) => ({
  width: '10px',
  height: '10px',
  margin: '0 5px',
  backgroundColor: '#525252',
  borderRadius: '50%',
  animation: `${waveAnimation} 1.2s infinite ease-in-out`,
  animationDelay: delay,
}));

const Dotwave = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    height='100px'
  >
    <Dot delay='0s' />
    <Dot delay='0.1s' />
    <Dot delay='0.2s' />
    <Dot delay='0.3s' />
    <Dot delay='0.4s' />
  </Box>
);

export default Dotwave;
