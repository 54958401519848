import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import {
  Box,
  Grid,
  Button,
  Modal,
  TextField,
  Typography,
  Fab,
} from '@mui/material';
import { Delete, EditOutlined } from '@mui/icons-material';
import Section1 from '../Home/Section1';
import CircularProgress from '@mui/material/CircularProgress';
import {
  add_user,
  all_user,
  delete_user,
  update_user,
} from '../../Utils/Helper';
import { UserContext } from '../Context/List';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
// import { DateField } from '@mui/x-date-pickers/DateField';
// import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
};

export default function DessertTable() {
  const [open, setOpen] = useState(false);
  const [call, setcall] = useState(false);
  const [name, setName] = useState('');

  const [date, setDate] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [page, setPage] = useState(1);
  const { userList, setuserList } = useContext(UserContext);
  // const [value, setValue] = React.useState(dayjs('2022-04-17'));

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);

    setName('');
    setPassword('');
    setDate('');
    setEditMode(false);
    setCurrentUserId(null);
  };

  const handleSave = () => {
    setloading(true);
    const NewData = {
      name: name,
      password: password,
      expired_at: date,
    };

    const changePassword = {
      new_password: password,
      expire_date: date,
    };

    if (editMode) {
      update_user(changePassword, currentUserId)
        .then((data) => {
          setcall(!call);
          handleClose();
          setloading(false);
        })
        .catch((error) => {
          console.error('Rejected', error);
          setloading(false);
        });
    } else {
      add_user(NewData)
        .then((data) => {
          setcall(!call);
          handleClose();
          setloading(false);
        })
        .catch((error) => {
          console.error('Rejected', error);
          setloading(false);
        });
    }
  };

  useEffect(() => {
    setloading(true);
    all_user()
      .then((data) => {
        setuserList(data.users);
        setloading(false);
      })
      .catch((error) => {
        console.error('Rejected:', error);
        setloading(false);
      });
  }, [call]);

  const handleDelete = (id, email) => {
    Swal.fire({
      title: 'Are you sure you want to delete this user?',
      text: `${email}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        delete_user(id)
          .then((data) => {
            setcall(!call);
          })
          .catch((error) => {
            console.error('Rejected:', error);
          });
      }
    });
  };

  const handleEdit = (user) => {
    setName(user.username);
    setPassword(user.password);
    setDate(formatDate(user.expire_at));
    setCurrentUserId(user.user_id);
    setEditMode(true);
    handleOpen();
  };

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No User</Box>
      </StyledGridOverlay>
    );
  }
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };
  const columns = [
    {
      field: 'user_id',
      headerName: 'Id',
      headerAlign: 'center',
      align: 'center',
      resizable: false,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Username',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      flex: 1,
    },
    {
      field: 'password',
      headerName: 'Password',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      flex: 1,
    },
    {
      field: 'expire_at',
      headerName: 'Expire Date',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'EEE, dd MMM yyyy');
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      resizable: false,
      headerAlign: 'center',
      flex: window.innerWidth > 620 ? 1 : 1.5,
      renderCell: (params) => (
        <>
          <Fab
            color='primary'
            aria-label='edit'
            size='small'
            onClick={() => handleEdit(params.row)}
          >
            <EditOutlined
              sx={{
                color: 'white',
                cursor: 'pointer',
                fontSize: '18px',
              }}
            />
          </Fab>

          <Fab
            color='primary'
            aria-label='delete'
            size='small'
            sx={{ marginLeft: '10px' }}
            onClick={() => handleDelete(params.id, params.row.email)}
          >
            <Delete
              sx={{
                color: 'white',
                cursor: 'pointer',
                fontSize: '18px',
              }}
            />
          </Fab>
        </>
      ),
    },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#F5F5F7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#DCE0E6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1C1C1C',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#F5F5F5' : '#fff',
    },
  }));

  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Section1
        content=''
        height={'70px'}
        slider={false}
        inputhide={'true'}
        mainMenuHidden={false}
      />
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'85%'}
        >
          <Grid
            item
            display={'flex'}
            justifyContent={'end'}
            alignItems={'center'}
            marginY={2}
            xs={12}
          >
            <button
              onClick={handleOpen}
              style={{
                height: '30px',
                width: '100px',
                backgroundColor: '#182c61',
                borderRadius: '5px',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              {' '}
              Add New
            </button>
          </Grid>
        </Grid>
        <Box
          sx={{
            overflowX: 'scroll',
            width: '90%',
            height: 400,
            minHeight: '70vh',
            marginBottom: 2,
          }}
        >
          <DataGrid
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
            className='tableData'
            rows={userList}
            columns={columns}
            rowsPerPageOptions={[10, 20, 50]}
            initialState={{
              ...userList.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            page={page - 1}
            onPageChange={(newPage) => setPage(newPage + 1)}
            getRowId={(row) => row.user_id}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={loading}
            sx={{
              width: { xs: '700px', sm: '700px', md: '100%' },
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: 'none',
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: 'none',
                },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#182c61',
                fontSize: '16px',
                textAlign: 'center',
                color: '#fff',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                textAlign: 'center',
              },
              '& .MuiDataGrid-sortIcon': {
                color: '#fff',
              },
              '& .MuiDataGrid-menuIconButton': {
                opacity: 1,
                color: 'white',
              },
            }}
          />
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {loading ? (
            <Grid
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'60vh'}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Typography
                id='modal-modal-title'
                textAlign={'center'}
                variant='h6'
                component='h2'
              >
                {editMode ? 'Edit User Details' : 'Enter Your Details'}
              </Typography>
              <Box
                component='form'
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <TextField
                  label='Username'
                  variant='outlined'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  disabled={editMode}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      '& fieldset': {
                        borderColor: '#1c2d62',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1c2d62',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1c2d62',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#1c2d62',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#1c2d62',
                    },
                  }}
                />

                <TextField
                  label={editMode ? 'New Password' : 'Password'}
                  variant='outlined'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      '& fieldset': {
                        borderColor: '#e4ecfa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1c2d62',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1c2d62',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#1c2d62',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#1c2d62',
                    },
                  }}
                />

                <TextField
                  label={editMode ? 'New Expired at' : 'Expired at'}
                  type='date'
                  variant='outlined'
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#1c2d62' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '5px',
                      '& fieldset': {
                        borderColor: '#e4ecfa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1c2d62',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1c2d62',
                      },
                    },
                  }}
                />

                <Button
                  variant='contained'
                  onClick={handleSave}
                  disabled={
                    name.length == 0 || password.length == 0 || date.length == 0
                      ? true
                      : false
                  }
                  sx={{
                    backgroundColor: '#1c2d62',
                    color: '#fff',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#115293',
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Grid>
  );
}
