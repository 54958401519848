import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Component/Home/Home';
import Photos from './Component/Photos/Photos';
import SignIn from './Component/SignIn/Signin';
import Perticular_image from './Component/Photo_detail/Perticular_image';
import SearchByImage from './Component/SearchImage/SearchByImage';
import './css/global.css';
import UploadPhoto from './Component/uploadPhoto/UploadPhoto';
import { useContext, useEffect } from 'react';
import Footer from './Component/Footer/Footer';
import { UserContext } from './Component/Context/List';
import NotFound from './Component/notfound/Notfound';
import { Grid } from '@mui/material';
import './css/globalscrollbar.css';
import DessertTable from './Component/table/Table';
import { Category_type } from './Utils/Helper';
import Image_detail from './Component/imageDetail/Image_detail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Category from './Component/category/Category';
function App() {
  const { Role, setListCategory } = useContext(UserContext);
  useEffect(() => {
    Category_type()
      .then((data) => {
        setListCategory(data);
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  }, []);

  return (
    <Grid
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <BrowserRouter>
        <Grid flex={1}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/photos/:category/:text' element={<Photos />} />
            <Route path='/photos/:category' element={<Photos />} />
            <Route path='/photos' element={<Photos />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/Image_detail/:Image' element={<Perticular_image />} />
            <Route path='/SearchByImage' element={<SearchByImage />} />
            <Route path='*' element={<NotFound />} />
            <Route path='/users' element={<DessertTable />} />
            <Route path='/allImages' element={<Image_detail />} />
            <Route path='/category' element={<Category />} />
            <Route
              path='/UploadPhoto'
              element={Role ? <UploadPhoto /> : <Home />}
            />
          </Routes>
        </Grid>
        <ConditionalFooter></ConditionalFooter>
      </BrowserRouter>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          height: '50px',
          width: '50px',
          backgroundColor: '#25d366',
          borderRadius: '50%',
        }}
      >
        <a href='https://wa.me/9016821568' target='_blank'>
          <WhatsAppIcon
            sx={{
              color: 'white',
              fontSize: '25px',
            }}
          ></WhatsAppIcon>
        </a>
      </Grid>
    </Grid>
  );
}

const ConditionalFooter = () => {
  const location = useLocation();
  const excludeFooterPaths = ['*']; // Add paths where you don't want to show the footer

  if (excludeFooterPaths) {
    return <Footer />;
  }
  return null;
};

export default App;
