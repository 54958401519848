import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Backdrop,
  Autocomplete,
} from '@mui/material';
import { UserContext } from '../Context/List';
import React, { useEffect, useRef, useState, useContext } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Navbar from '../Navbar/Navbar';
import Uploadbtn from '../Common/Uploadbtn';
import { Delete, CloseOutlined } from '@mui/icons-material';
import '../../css/global.css';
import {
  Category_type,
  Upload_image_multi,
  show_tost,
} from '../../Utils/Helper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from '@mui/material/Modal';
import { MenuItem } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function UploadPhoto({ handlecloseupload }) {
  var availableTags = ['upper', 'lower', 'left', 'right', 'top'];
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [category, setCategory] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const [Total, setTotal] = React.useState(0);
  const [TotalDone, setTotalDone] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [notUpload, setNotUpload] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const { setrefresh } = useContext(UserContext);

  const handleCloseGrid = () => {
    setShowGrid(false);
    window.location.reload();
  };
  const handleOpen = (file) => {
    setRetryFile(file);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [retryFile, setRetryFile] = useState(null);

  const handleBoxClick = useRef(() => {
    inputRef.current.click();
  }).current;
  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleUploadData = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      show_tost('error', 'Please select a category');
      return;
    }
    if (selectedFile.length === 0 && notUpload.length === 0) {
      show_tost('error', 'Please select a file first');
      return;
    }

    let list = selectedFile;

    const userData = {
      category: selectedCategory,
      files: list,
    };

    setTotal(list.length);
    setLoading(true);
    setrefresh(true);
    const simpleupload = true;
    try {
      await Upload_image_multi(
        userData,
        setProgress,
        setTotalDone,
        setNotUpload,
        simpleupload
      );
      setSelectedFile([]);
      setSelectedCategory('');
    } catch (error) {
      console.error('Rejected:', error);

      setSelectedFile((prevFiles) =>
        prevFiles.map((file) => ({ ...file, uploadStatus: 'fail' }))
      );
    } finally {
      setLoading(false);
      setrefresh(false);
    }
  };

  const handleRetryUpload = async () => {
    if (!selectedCategory) {
      show_tost('error', 'Please select a category');
      return;
    }

    if (!retryFile) {
      show_tost('error', 'No file selected for retry');
      return;
    }

    try {
      const userData = {
        category: selectedCategory,
        files: [retryFile],
      };
      const simpleupload = false;

      await Upload_image_multi(
        userData,
        setProgress,
        setTotalDone,
        setNotUpload,
        simpleupload
      );

      setNotUpload((prevFiles) =>
        prevFiles.filter((file) => file !== retryFile)
      );
      setRetryFile(null);
    } catch (error) {
      console.error('Rejected:', error);

      setNotUpload((prevFiles) =>
        prevFiles.map((file) =>
          file === retryFile ? { ...file, uploadStatus: 'fail' } : file
        )
      );
    } finally {
      setSelectedCategory('');
      handleClose();
    }
  };

  useEffect(() => {
    Category_type()
      .then((data) => {
        setCategory(data.map((cat) => cat.category));
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  }, []);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleFileSelect = (e) => {
    let images = [];
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let file = files[i];
      reader.onloadend = () => {
        setSelectedFile((preValue) => [
          ...preValue,
          {
            filename: file.name,
            filetype: file.type,
            fileimage: reader.result,
            datetime: file.lastModifiedDate.toLocaleString('en-IN'),
            filesize: filesizes(file.size),
            file: file,
          },
        ]);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (id) => {
    const result = selectedFile.filter((data, index) => index !== id);
    setSelectedFile(result);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          backgroundColor: 'white',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px 30px',
          borderRadius: '15px',
        }}
      >
        <CircularProgress variant='determinate' {...props} />

        <Typography
          variant='caption'
          component='div'
          color='#1c2d62'
          sx={{ color: 'black', marginTop: '10px' }}
        >
          {`${Math.round(props.totaldone)} / ${Math.round(props.total)}`}
        </Typography>
        <Typography
          variant='caption'
          component='div'
          color='#1c2d62'
          sx={{ color: 'black', fontSize: '15px', fontWeight: 550 }}
        >
          uploading...
        </Typography>
      </Box>
    );
  }

  return (
    <Grid position='relative'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        className='processloader'
      >
        <CircularProgressWithLabel
          value={progress}
          total={Total}
          totaldone={TotalDone}
        />
      </Backdrop>
      <Grid
        container
        Height='90vh'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          width: '100%',
        }}
      >
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          className='UploadComponent'
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            position: 'relative',
            height: { xs: '400px', md: '500px' },
            overflow: 'hidden',
            width:
              selectedFile.length > 0
                ? { xl: '50%', lg: '80%', md: '90%', xs: '90%' }
                : { xl: '40%', lg: '50%', md: '60%', xs: '90%' },

            paddingY: 4,
            overflowY: 'scroll !important',
          }}
        >
          <Box onClick={handlecloseupload}>
            <CloseOutlined
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: '#182c51',
                cursor: 'pointer',
              }}
            ></CloseOutlined>
          </Box>
          <Typography
            variant='h4'
            sx={{
              color: 'rgba(0,0,0,0.74)',
              fontSize: { xs: '20px', md: '23px' },
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Upload Your Image
          </Typography>

          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 2, md: 4 },
              width: '95%',
              mt: 2,
            }}
          >
            <Box
              component='section'
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              sx={{
                p: 5,
                height: '350px',
                width:
                  selectedFile.length > 0
                    ? { xl: '40%', lg: '40%', md: '50%', xs: '90%' }
                    : { xl: '80%', lg: '80%', md: '70%', xs: '90%' },
                border: '3px dashed #182C61',
                borderRadius: '10px',
                color: 'rgba(0,0,0,0.74)',
                cursor: 'pointer',
                backgroundColor: 'rgb(228, 236, 250)',
              }}
              onClick={handleBoxClick}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.preventDefault();
                handleFileSelect(e);
              }}
            >
              <input
                ref={inputRef}
                type='file'
                accept='image/*'
                style={{ display: 'none' }}
                onChange={handleFileSelect}
                multiple
              />
              <UploadFileIcon sx={{ fontSize: '35px' }} />
              <Typography textAlign={'center'} variant='h6' mt={1}>
                Choose a file drop here
              </Typography>
            </Box>

            {selectedFile.length > 0 && (
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  width:
                    selectedFile.length > 0
                      ? { xl: '50%', lg: '40%', md: '40%', xs: '90%' }
                      : { xl: '40%', lg: '50%', md: '60%', xs: '90%' },
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  sx={{
                    height: '284px',
                    width: '100%',
                    border: '3px solid #1c2d62',
                    borderRadius: '10px',
                    color: 'rgba(0,0,0,0.74)',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      height: '100%',
                    }}
                  >
                    <Grid
                      sx={{
                        width: '100%',
                        height: '15%',
                        backgroundColor: '#1c2d62',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        textAlign={'center'}
                        sx={{ color: 'white', fontWeight: 600 }}
                      >
                        Selected Files
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        width: '100%',
                        height: '85%',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                      }}
                    >
                      <Grid
                        container
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                          width: '100%',
                          height: 'auto',
                        }}
                      >
                        {selectedFile.map((data, index) => (
                          <Grid
                            key={index}
                            item
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            p={2}
                            sx={{
                              borderBottom:
                                selectedFile.length - 1 !== index
                                  ? '1px solid #ccc'
                                  : 'none',
                              width: '100%',
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'}>
                              <img
                                src={data.fileimage}
                                alt={data.filename}
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  objectFit: 'cover',
                                  borderRadius: '10px',
                                }}
                              />
                              <Box ml={2}>
                                <Typography
                                  variant='body2'
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {data.filename}
                                </Typography>
                                <Typography
                                  variant='body2'
                                  color='textSecondary'
                                >
                                  {data.filesize}
                                </Typography>
                              </Box>
                            </Box>

                            <Delete
                              onClick={() => DeleteSelectFile(index)}
                              sx={{ cursor: 'pointer', color: 'red' }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{ minWidth: 200, mt: 2 }}
                >
                  <Autocomplete
                    sx={{
                      m: 1,
                      width: 500,
                    }}
                    multiple
                    id='tags-standard'
                    options={category}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ justifyContent: 'space-between' }}
                        {...props}
                      >
                        {option}
                        {selected}
                      </MenuItem>
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setSelectedCategory(newValue);
                      } else if (newValue && newValue.inputValue) {
                        setSelectedCategory(newValue.inputValue);
                      } else {
                        setSelectedCategory(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Category'
                        placeholder='Select Category'
                      />
                    )}
                  />
                  <Uploadbtn
                    labelButton2='Upload File'
                    onClickButton2={handleUploadData}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          {notUpload.length > 0 && showGrid && (
            <>
              <Grid
                container
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                mt={5}
                sx={{
                  height: '350px',
                  border: '3px solid #1c2d62',
                  borderRadius: '10px',
                  width: { xl: '75%', lg: '75%', md: '65%', xs: '85%' },
                }}
              >
                <Typography
                  variant='h4'
                  sx={{
                    color: 'rgba(0,0,0,0.74)',
                    fontSize: { xs: '20px', md: '23px' },
                    fontWeight: 'bold',
                    textAlign: 'center',
                    backgroundColor: '#1c2d62',
                    width: '100%',
                    color: 'white',
                    padding: '11px 0px',
                    position: 'relative',
                  }}
                >
                  Image upload failed.
                  <CloseOutlined
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      top: 3,
                      right: 5,
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                    onClick={handleCloseGrid}
                  ></CloseOutlined>
                </Typography>
                <Grid
                  sx={{
                    width: '100%',
                    height: '85%',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  {notUpload.map((data, index) => (
                    <Grid
                      key={index}
                      item
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      p={2}
                      sx={{
                        borderBottom:
                          notUpload.length - 1 !== index
                            ? '1px solid #ccc'
                            : 'none',
                        width: '100%',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'}>
                        <img
                          src={data.fileimage}
                          alt={data.filename}
                          style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                          }}
                        />
                        <Box ml={2}>
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 'bold' }}
                          >
                            {data.filename}
                          </Typography>
                          <Typography variant='body2' color='textSecondary'>
                            {data.filesize}
                          </Typography>
                        </Box>
                      </Box>

                      <CloudUploadIcon
                        onClick={() => handleOpen(data)}
                        sx={{ cursor: 'pointer', color: '#182c61' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={style}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Autocomplete
            sx={{
              m: 1,
              width: 500,
            }}
            multiple
            id='tags-standard'
            options={category}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <MenuItem
                key={option}
                value={option}
                sx={{ justifyContent: 'space-between' }}
                {...props}
              >
                {option}
                {selected}
              </MenuItem>
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setSelectedCategory(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedCategory(newValue.inputValue);
              } else {
                setSelectedCategory(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Category'
                placeholder='Select Category'
              />
            )}
          />
          {/* <Autocomplete
            value={selectedCategory}
            options={category}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setSelectedCategory(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedCategory(newValue.inputValue);
              } else {
                setSelectedCategory(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = options.filter((option) =>
                option.toLowerCase().includes(params.inputValue.toLowerCase())
              );

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }

              if (option.inputValue) {
                return option.inputValue;
              }

              return option;
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{
              width: '50%',
              marginRight: '10px',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(28 45 98)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(28 45 98)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(28 45 98)',
              },
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select Category'
                sx={{
                  '.MuiInputBase-root': {
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  style: { top: -5, fontSize: '13px' },
                }}
              />
            )}
          /> */}

          <Uploadbtn
            labelButton2='Upload File'
            onClickButton2={handleRetryUpload}
          />
        </Box>
      </Modal>
    </Grid>
  );
}

export default UploadPhoto;
