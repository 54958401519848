import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Photobtn from '../Common/Photobtn';
import '../../css/commonbtn.css';
import All from './All';
import { useNavigate, useParams } from 'react-router-dom';
import { AllImages, Search_by_text, categoryTypeGet } from '../../Utils/Helper';
import { UserContext } from '../Context/List';

function Option_photo(props) {
  const navigate = useNavigate();
  const { listCategory, infinity, setSearchList } = useContext(UserContext);
  const { category, text } = useParams();
  const [currentComponent, setCurrentComponent] = useState('Latest');
  const [pagination, setpagination] = useState(1);
  const [activepage, setactivepage] = useState(1);
  const [images, setimages] = useState([]);
  const [noImages, setnoImages] = useState(false);
  const [loading, setloading] = useState(false);

  const handaldefult = () => {
    setpagination(1);
    setactivepage(1);
  };

  useEffect(() => {
    setimages([]);
    setloading(true);
    if (!category && !text) {
      AllImages(activepage, currentComponent)
        .then((data) => {
          setSearchList(data);
          setimages(data.images);
          if (data.images.length > 0) {
            setnoImages(false);
          } else {
            setnoImages(true);
          }
          setpagination(data.pages);
          setactivepage(data.page);
          setloading(false);
        })
        .catch((error) => {
          console.error('Rejected:', error);
          setloading(false);
          setnoImages(true);
        });
    } else if (category && text) {
      const categories = listCategory.map((item) => item.category);
      const categoryExists = categories.includes(category.toUpperCase());
      if (categoryExists) {
        const NewData = { text: text, category: category.toUpperCase() };
        Search_by_text(NewData, activepage)
          .then((data) => {
            setSearchList(data);
            setimages(data.images);
            if (data.images.length > 0) {
              setnoImages(false);
            } else {
              setnoImages(true);
            }
            setpagination(data.pages);
            setactivepage(data.page);
            setloading(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
            setnoImages(true);
            setloading(false);
          });
      } else {
        navigate(`/photos/all/${text}`);
        const NewData = { text: text };
        Search_by_text(NewData, activepage)
          .then((data) => {
            setSearchList(data);
            setimages(data.images);
            if (data.images.length > 0) {
              setnoImages(false);
            } else {
              setnoImages(true);
            }
            setpagination(data.pages);
            setactivepage(data.page);
            setloading(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
            setnoImages(true);
            setloading(false);
          });
      }
    } else if (category) {
      const categories = listCategory.map((item) => item.category);
      const categoryExists = categories.includes(category.toUpperCase());
      if (categoryExists) {
        categoryTypeGet(activepage, category.toUpperCase())
          .then((data) => {
            setSearchList(data);
            setimages(data.images);
            if (data.images.length > 0) {
              setnoImages(false);
            } else {
              setnoImages(true);
            }
            setpagination(data.pages);
            setactivepage(data.page);
            setloading(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
            setnoImages(true);
            setloading(false);
          });
      } else {
        navigate(`/photos/all`);
        categoryTypeGet(activepage, 'ALL')
          .then((data) => {
            setSearchList(data);
            setimages(data.images);
            if (data.images.length > 0) {
              setnoImages(false);
            } else {
              setnoImages(true);
            }
            setpagination(data.pages);
            setactivepage(data.page);
            setloading(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
            setnoImages(true);
            setloading(false);
          });
      }
    }
  }, [activepage, currentComponent, category, text, infinity]);

  return (
    <>
      <Grid container mt={5}>
        {text == undefined && category == undefined ? (
          <Grid
            item
            xs={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            ml={1}
          >
            <Photobtn
              active={currentComponent === 'All'}
              label={'All'}
              onClick={() => {
                setCurrentComponent('All');
                handaldefult();
              }}
            />
            <Photobtn
              active={currentComponent === 'Latest'}
              label={'Latest'}
              onClick={() => {
                setCurrentComponent('Latest');
                handaldefult();
              }}
            />
            <Photobtn
              active={currentComponent === 'MostDownload'}
              label={'Most Downloads'}
              onClick={() => {
                setCurrentComponent('MostDownload');
                handaldefult();
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid container>
        <All
          currentComponent={currentComponent}
          pagination={pagination}
          activepage={activepage}
          setpagination={setpagination}
          setactivepage={setactivepage}
          images={images}
          noImages={noImages}
          loading={loading}
          setimages={setimages}
          setnoImages={setnoImages}
          setloading={setloading}
        />
      </Grid>
    </>
  );
}

export default Option_photo;
