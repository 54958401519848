import axios from 'axios';
import { domain } from './Domain';

const get_header = () => {
  return {
    'Content-Type': 'application/json',
  };
};

const get_header1 = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
  };
};

const get_header2 = () => {
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
  };
};

export const post = (url, data, success, fail) => {
  const final_url = domain + url;
  axios
    .post(final_url, data, { headers: get_header() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const delet = (url, success, fail) => {
  const final_url = domain + url;
  axios
    .delete(final_url, { headers: get_header1() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const patch = (url, data, success, fail) => {
  const final_url = domain + url;
  axios
    .patch(final_url, data, { headers: get_header1() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const get = (url, success, fail) => {
  const final_url = domain + url;
  axios
    .get(final_url, { headers: get_header() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const imagePost = (url, data, onSuccess, onError) => {
  const final_url = domain + url;
  const formData = new FormData();
  formData.append('image', data);
  axios
    .post(final_url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

export const category_patch = (url, data, success, fail) => {
  const final_url = domain + url;
  const formData = new FormData();
  formData.append('title_image', data.title_image);
  formData.append('category', data.category);
  axios
    .patch(final_url, data, { headers: get_header2() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const categoryPost = (url, data, onSuccess, onError) => {
  const final_url = domain + url;

  const formData = new FormData();
  formData.append('title_image', data.title_image);
  formData.append('category', data.category);
  axios
    .post(final_url, formData, {
      headers: get_header2(),
    })
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};
export const UpdatecategoryPost = (url, data, onSuccess, onError) => {
  const final_url = domain + url;

  const formData = new FormData();
  data.forEach((item) => {
    formData.append('category', item.toLowerCase().replace(/\s+/g, '_'));
  });
  axios
    .post(final_url, formData, {
      headers: get_header2(),
    })
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};
// with token

export const auth_get = (url, success, fail) => {
  const final_url = domain + url;
  axios
    .get(final_url, { headers: get_header1() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        sessionStorage.setItem('authError', 'Please log in.');
        window.location.href = '/signin';
        return true;
      }
      fail(error);
    });
};

export const auth_delete = (url, success, fail) => {
  const final_url = domain + url;
  axios
    .delete(final_url, { headers: get_header1() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const auth_post = (url, data, success, fail) => {
  const final_url = domain + url;
  axios
    .post(final_url, data, { headers: get_header1() })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const download_get = (url, success, fail) => {
  const final_url = domain + url;
  axios
    .get(final_url, {
      headers: get_header1(),
      responseType: 'blob',
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      fail(error);
    });
};

export const multi_Upload_post = async (url, data, success, fail) => {
  const final_url = domain + url;

  if (data.files.length === 0) {
    const error = new Error('No files Provided!');
    error.status = 'Fail';
    fail(error);
    return;
  }

  const form = new FormData();
  for (const file of data.files) {
    form.append('files', file);
  }
  for (const category of data.category) {
    form.append('category', category);
  }

  for (const [key, value] of form.entries()) {
    if (typeof value === 'object') {
      console.log(`${key}: A file`);
    } else {
      console.log(`${key}: ${value}`);
    }
  }

  try {
    const response = await axios.post(final_url, form, {
      headers: get_header2(),
    });
    success(response.data);
  } catch (error) {
    fail(error);
  }
};
