import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { Fab, Autocomplete } from '@mui/material';
import Section1 from '../Home/Section1';
import { CloseSharp, Delete, EditOutlined } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import {
  AllImages,
  Category_type,
  Delete_by_id,
  Search_by_text,
  heightWidthChange,
  update_category,
} from '../../Utils/Helper';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import '../../css/global.css';
import UploadPhoto from '../uploadPhoto/UploadPhoto';
import { UserContext } from '../Context/List';
import { IconButton, MenuItem } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#182c61',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#182c61',
            },
        },
      },
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
};

export default function FullFeaturedCrudGrid() {
  const [rowModesModel, setRowModesModel] = useState({});
  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [deletes, setdeletes] = useState('');
  const { refresh } = useContext(UserContext);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategory1, setSelectedCategory1] = useState([]);
  const [category, setCategory] = useState([]);
  const [open, setOpen] = useState(false);
  const [hintlist, setHintlist] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  useEffect(() => {
    setRows([]);
    if (searchQuery.length >= 3) {
      setLoading(true);

      const category =
        selectedCategory.toUpperCase() === 'ALL'
          ? ''
          : selectedCategory.toUpperCase();
      const NewData = {
        text: searchQuery,
        category,
      };
      Search_by_text(NewData, paginationModel.page + 1)
        .then((data) => {
          const transformedData = data?.images.map((item) => ({
            id: item.img_id,
            image: item.image_url,
            name: item.filename.split('/').pop(),
            height: item.height,
            width: item.width,
            category: item.categories,
            size: item.file_size,
          }));
          setHintlist(data?.hint_list);
          setRows(transformedData);
          setOriginalRows(transformedData);
          setRowCount(data.total);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Rejected:', error);
          setLoading(false);
        });
      setLoading(false);
    } else if (selectedCategory !== '') {
      setLoading(true);

      const category =
        selectedCategory.toUpperCase() === 'ALL'
          ? ''
          : selectedCategory.toUpperCase();
      const NewData = {
        text: searchQuery,
        category,
      };
      Search_by_text(NewData, paginationModel.page + 1)
        .then((data) => {
          const transformedData = data?.images.map((item) => ({
            id: item.img_id,
            image: item.image_url,
            name: item.filename.split('/').pop(),
            height: item.height,
            width: item.width,
            category: item.categories,
            size: item.file_size,
          }));
          setHintlist(data?.hint_list);
          setRows(transformedData);
          setOriginalRows(transformedData);
          setRowCount(data.total);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Rejected:', error);
          setLoading(false);
        });
      setLoading(false);
    } else if (searchQuery === '' && selectedCategory === '') {
      setLoading(true);

      AllImages(paginationModel.page + 1, 'All')
        .then((data) => {
          setHintlist(data?.hint_list);
          const transformedData = data?.images.map((item) => ({
            id: item.img_id,
            image: item.image_url,
            name: item.filename.split('/').pop(),
            height: item.height,
            width: item.width,
            category: item.category,
            size: item.file_size,
          }));
          setRows(transformedData);
          setOriginalRows(transformedData);
          setRowCount(data.total);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Rejected:', error);
          setLoading(false);
        });
      setLoading(false);
    }
  }, [
    paginationModel.page,
    pageSize,
    deletes,
    searchQuery,
    selectedCategory,
    refresh,
    loading,
  ]);

  useEffect(() => {
    Category_type()
      .then((data) => {
        const allCategory = {
          category: 'ALL',
        };
        const updatedCategories = [allCategory, ...data];
        setCategory(updatedCategories.map((e) => e.category));
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  }, []);

  const handleImageClick = (src) => {
    window.open(`/Image_detail/${src}`, '_blank');
  };

  const NumericTextField = ({ params }) => {
    const handleInputChange = React.useCallback(
      (event) => {
        const inputValue = event.target.value;

        // Allow only numeric values and a single decimal point
        let numericValue = inputValue.replace(/[^0-9.]/g, '');

        // Ensure only one decimal point is present
        const decimalIndex = numericValue.indexOf('.');
        if (decimalIndex !== -1) {
          numericValue =
            numericValue.substring(0, decimalIndex + 1) +
            numericValue.substring(decimalIndex + 1).replace(/\./g, '');
        }

        // Update the cell value
        params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value: numericValue,
        });
      },
      [params]
    );

    return (
      <TextField
        value={params.value || ''}
        onChange={handleInputChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        sx={{
          '& .MuiInputBase-input': { textAlign: 'center' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { border: 'none' },
            '&:hover fieldset': { border: '1px solid rgba(0, 0, 0, 0.23)' },
            '&.Mui-focused fieldset': {
              border: '1px solid rgba(0, 0, 0, 0.23)',
            },
          },
        }}
        fullWidth
      />
    );
  };
  const handleSaveDetail = (id, height, width, size) => {
    const newSize = parseFloat(size);

    let myEle = rows.filter((ele) => {
      return ele.id == id;
    });
    setLoading(true);
    if (myEle.length > 0) {
      if (
        myEle[0].height !== height ||
        myEle[0].width !== width ||
        myEle[0].size !== size
      ) {
        // const formattedSize = size.toUpperCase();

        const newData = {
          height: height,
          width: width,
          file_size: newSize,
        };

        heightWidthChange(newData, id)
          .then((data) => {
            setRows(
              rows.map((row) =>
                row.id === id
                  ? {
                      ...row,
                      height: height,
                      width: width,
                      size: size,
                    }
                  : row
              )
            );
            setLoading(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
            setLoading(false);
          });
      }
    }

    if (selectedCategory1.length > 0) {
      const newData = selectedCategory1;
      update_category(newData, id)
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          console.error('Rejected', error);
          setLoading(false);
        });
    }
  };

  const handleDeleteclick = (id, image) => {
    Swal.fire({
      title: 'Are you sure you want to delete this Image?',
      text: `Id : ${id}`,
      imageUrl: image,
      imageWidth: 400,
      imageHeight: 200,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      customClass: {
        image: 'custom-image',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Delete_by_id(id)
          .then((response) => {
            setdeletes(response);
          })
          .catch((error) => {});
      }
    });
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    handleSaveDetail(
      updatedRow.id,
      updatedRow.height,
      updatedRow.width,
      updatedRow.size
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const options = hintlist ? hintlist.slice(0, 10) : [];
  const columns = [
    {
      field: 'image',
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      headerName: 'Image',
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <>
          <img
            src={params.row.image}
            alt='image'
            onClick={(event) => {
              event.stopPropagation();
              setSelectedImage(params.row.image);
              setOpen1(true);
            }}
            style={{ width: 50, height: 50, cursor: 'pointer' }}
          />

          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <img
                src={selectedImage}
                alt='Selected'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
              />
              <CloseSharp
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  cursor: 'pointer',
                }}
                onClick={handleClose1}
              />
            </Box>
          </Modal>
        </>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      align: 'center',
      resizable: false,
      headerAlign: 'center',
      flex: 1,
      editable: false,
    },
    {
      field: 'category',
      headerName: 'Category',
      align: 'center',
      resizable: true,
      headerAlign: 'center',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          multiple
          id='tags-standard'
          options={category}
          value={params.value}
          onChange={(event, newValue) => {
            setSelectedCategory1(newValue);
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Select Category'
              sx={{
                height: '100%',
                width: '100%',
                minWidth: '200px',
                overflow: 'scroll',

                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexDirection: 'column',

                '& .MuiInputBase-input': { textAlign: 'center' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { border: 'none' },
                  '&:hover fieldset': {
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                  },
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} selected={selected}>
              {option}
            </MenuItem>
          )}
        />
      ),
    },
    {
      field: 'height',
      headerName: 'Height (mm)',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      type: 'number',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <TextField
          type='number'
          value={params.value}
          onChange={(event) =>
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value,
            })
          }
          sx={{
            '& .MuiInputBase-input': { textAlign: 'center' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { border: 'none' },
              '&:hover fieldset': { border: 'none' },
              '&.Mui-focused fieldset': { border: 'none' },
            },
          }}
          fullWidth
        />
      ),
    },
    {
      field: 'width',
      headerName: 'Width (mm)',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      editable: true,
      flex: 1,
      type: 'number',
      renderEditCell: (params) => (
        <TextField
          type='number'
          value={params.value}
          onChange={(event) =>
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value,
            })
          }
          sx={{
            '& .MuiInputBase-input': { textAlign: 'center' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { border: 'none' },
              '&:hover fieldset': { border: 'none' },
              '&.Mui-focused fieldset': { border: 'none' },
            },
          }}
          fullWidth
        />
      ),
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'center',
      resizable: false,
      align: 'center',
      editable: true,
      flex: 1,
      type: 'integer',
      renderEditCell: (params) => <NumericTextField params={params} />,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'center',
      resizable: false,
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      renderCell: (params) => {
        const row = params.row;
        const { id, image } = row;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return (
            <>
              <Fab
                color='primary'
                aria-label='edit'
                size='small'
                onClick={handleSaveClick(id)}
                sx={{ marginX: '10px !important' }}
              >
                <SaveIcon
                  sx={{
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '21px',
                  }}
                />
              </Fab>

              <Fab
                color='primary'
                aria-label='edit'
                size='small'
                onClick={handleCancelClick(id)}
              >
                <CancelIcon
                  sx={{
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '21px',
                  }}
                />
              </Fab>
            </>
          );
        }

        return (
          <>
            <Fab
              color='primary'
              aria-label='edit'
              size='small'
              onClick={handleEditClick(id)}
              sx={{ marginX: '10px !important' }}
            >
              <EditOutlined
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '21px',
                }}
              />
            </Fab>

            <Fab
              color='primary'
              aria-label='edit'
              size='small'
              onClick={() => {
                handleDeleteclick(id, image);
              }}
            >
              <Delete
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '21px',
                }}
              />
            </Fab>
          </>
        );
      },
    },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#F5F5F7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#DCE0E6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1C1C1C',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#F5F5F5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Images</Box>
      </StyledGridOverlay>
    );
  }
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Section1
        content=''
        height={'70px'}
        slider={false}
        inputhide={'true'}
        mainMenuHidden={false}
      />

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',

          width: '90%',
          height: { md: '50px', sm: '100px' },
          marginTop: 3,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: '50px',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'flex-start',
          }}
        >
          {' '}
          <button
            onClick={handleOpen}
            style={{
              height: '35px',
              width: '125px',
              backgroundColor: '#182c61',
              borderRadius: '5px',
              border: 'none',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            {' '}
            Upload Photos
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <UploadPhoto handlecloseupload={handleClose} />
          </Modal>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            // backgroundColor: 'blue',
          }}
        >
          <ThemeProvider theme={theme}>
            <Grid container display={'flex'} justifyContent={'center'}>
              <Grid item display={'flex'} justifyContent={'center'} xs={6}>
                <Autocomplete
                  value={selectedCategory}
                  options={category}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setSelectedCategory(newValue);
                    } else if (newValue && newValue.inputValue) {
                      setSelectedCategory(newValue.inputValue);
                    } else {
                      setSelectedCategory(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = options.filter((option) =>
                      option
                        .toLowerCase()
                        .includes(params.inputValue.toLowerCase())
                    );

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }

                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    return option;
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{
                    width: '100%',
                    marginRight: '10px',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgb(28 45 98)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgb(28 45 98)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgb(28 45 98)',
                    },
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Search By Category'
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          height: '45px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '45px',
                        },
                        '& .MuiInputLabel-root': {
                          lineHeight: '15px',
                        },
                      }}
                      InputLabelProps={{
                        style: { top: 0, fontSize: '14px' },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {params.InputProps.value && (
                              <IconButton
                                onClick={() =>
                                  params.onChange({ target: { value: '' } })
                                } // Clear the input field
                                size='small'
                                sx={{ p: 1 }}
                              >
                                <CloseIcon fontSize='small' />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                xs={6}
              >
                <Autocomplete
                  disableClearable
                  fullWidth
                  value={searchQuery}
                  freeSolo={false}
                  comboBox={false}
                  onInputChange={(event, newValue) => {
                    setSearchQuery(newValue);
                  }}
                  options={options.slice(0, 10)}
                  filterOptions={(options, state) => {
                    return options;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Search By Name'
                      variant='outlined'
                      id='demo-customized-select-label'
                      sx={{
                        marginBottom: 2,
                        width: '100%',
                        position: 'relative',
                        '& .MuiAutocomplete-root': {
                          height: '45px',
                        },
                        '& .MuiInputBase-root': {
                          height: '45px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '45px',
                        },
                        '& .MuiInputLabel-root': {
                          lineHeight: '13px',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { top: 0, height: '45px' },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </ThemeProvider>
        </Grid>
      </Grid>

      <Box
        sx={{
          overflowX: 'scroll',
          width: '90%',
          height: 400,
          marginY: 2,
          minHeight: '70vh',
        }}
      >
        <DataGrid
          loading={loading}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 50 } },
          }}
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          pagination
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          editMode='row'
          pageSizeOptions={[50]}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onRowClick={(params) => handleImageClick(params.row.id)}
          getRowHeight={(params) => {
            return rowModesModel[params.id]?.mode === GridRowModes.Edit
              ? 100
              : 50;
          }}
          sx={{
            cursor: 'pointer',
            width: { xs: '700px', sm: '700px', md: '100%' },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: 'none',
              },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#182c61',
              fontSize: '16px',
              textAlign: 'center',
              color: '#fff',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              textAlign: 'center',
            },
            '& .MuiDataGrid-sortIcon': {
              color: '#fff',
            },
            '& .MuiDataGrid-menuIconButton': {
              opacity: 1,
              color: 'white',
            },
          }}
        />
      </Box>
      {/* )} */}
    </Grid>
  );
}
