import React, { useEffect, useState } from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import {
  Box,
  Grid,
  Button,
  Modal,
  TextField,
  Typography,
  Fab,
  IconButton,
} from '@mui/material';
import { CloseSharp, Delete, EditOutlined } from '@mui/icons-material';
import Section1 from '../Home/Section1';
import Tooltip from '@mui/material/Tooltip';
import {
  Category_type,
  add_category,
  delete_category_id,
  edit_category,
  show_tost,
} from '../../Utils/Helper';

import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
};

export default function DessertTable() {
  const [categoryList, setCategoryList] = useState([]);

  const [open1, setOpen1] = useState(false);
  const [call, setcall] = useState(false);
  const [Category, setCategory] = useState('');
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);

  const [page, setPage] = useState(1);

  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState('');

  const [open3, setOpen3] = useState(false);
  const handleClose3 = () => setOpen3(false);
  const [open4, setOpen4] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Editcategory, setEditCategory] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileURL, setUploadedFileURL] = useState(null);
  const handleClose4 = () => {
    setOpen4(false);
    setSelectedRow(null);
    setUploadedFile(null);
    setUploadedFileURL(null);
    setFile(null);
    setFileURL('');
  };
  const handleIconClick = () => {
    setIsEditing(true);
  };

  const handleFileChangeInternal = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      const fileURL = URL.createObjectURL(file);
      setUploadedFileURL(fileURL);
      setIsEditing(false);
    }
    handleFileChange(event);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileURL(URL.createObjectURL(selectedFile));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      show_tost('error', 'Please select one image.');
    } else {
      const selectedFile = files[0];
      setFile(selectedFile);
      setFileURL(URL.createObjectURL(selectedFile));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const convertBytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setFile('');
    setFileURL('');
    setCategory('');
  };
  useEffect(() => {
    if (!open1 && !open4) {
      setFile(null);
      setFileURL('');
    }
  }, [open1, open4]);

  const handlecategorySave = () => {
    const NewData = {
      title_image: file,
      category: Category,
    };
    setloading1(true);
    add_category(NewData)
      .then((data) => {
        setcall(!call);
        handleClose1();
        setloading1(false);
      })
      .catch((error) => {
        console.error('Rejected', error);
        setloading1(false);
      });
  };

  useEffect(() => {
    setloading(true);
    Category_type()
      .then((data) => {
        setCategoryList(data);
        setloading(false);
      })
      .catch((error) => {
        console.error('Rejected:', error);
        setloading(false);
      });
  }, [call]);

  const handleCategoryEdit = (row) => {
    setSelectedRow(row);
    setEditCategory(row.category);
    setUploadedFile(null);
    setUploadedFileURL(null);
    setFile(null);
    setFileURL('');
    setOpen4(true);
  };

  const handleIconClick1 = () => {
    setFile(null);
    setFileURL('');
  };

  const handleEditcategorySave = (row) => {
    let NewData = {};

    if (uploadedFile !== null) {
      NewData.title_image = uploadedFile;
    }

    if (Editcategory !== selectedRow.category) {
      NewData.category = Editcategory;
    }
    setloading1(true);

    edit_category(NewData, selectedRow.category_id)
      .then((data) => {
        setcall(!call);
        handleClose4();
        setloading1(false);
      })
      .catch((error) => {
        console.error('Rejected', error);
        setloading1(false);
      });
  };

  const handleDeletecategory = (id, image) => {
    Swal.fire({
      title:
        'Are you sure you want to delete this category? This action will delete associated images.',
      text: `Id : ${id}`,
      imageUrl: image,
      imageWidth: 400,
      imageHeight: 200,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      customClass: {
        image: 'custom-image',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        delete_category_id(id)
          .then((response) => {
            setcall(!call);
          })
          .catch((error) => {});
      }
    });
  };

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Category</Box>
      </StyledGridOverlay>
    );
  }

  const column1 = [
    {
      field: 'category_id',
      headerName: 'Id',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      resizable: false,
    },
    {
      field: 'category',
      headerName: 'Category',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      resizable: false,
    },
    {
      field: 'category_img',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Image',
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          <img
            src={params.row.category_img}
            alt='image'
            onClick={(event) => {
              event.stopPropagation();
              setSelectedImage(params.row.category_img);
              setOpen3(true);
            }}
            style={{ width: 50, height: 50, cursor: 'pointer' }}
          />

          <Modal
            open={open3}
            onClose={handleClose3}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style3}>
              <img
                src={selectedImage}
                alt='Selected'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
              />
              <CloseSharp
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  cursor: 'pointer',
                }}
                onClick={handleClose3}
              />
            </Box>
          </Modal>
        </>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      flex: window.innerWidth > 620 ? 1 : 1.5,
      renderCell: (params) => (
        <>
          <Fab
            color='primary'
            aria-label='edit'
            size='small'
            onClick={() => {
              handleCategoryEdit(params.row);
            }}
            sx={{ marginRight: '5px !important' }}
          >
            <EditOutlined
              sx={{
                color: 'white',
                cursor: 'pointer',
                fontSize: '18px',
              }}
            />
          </Fab>
          <Modal
            open={open4}
            onClose={handleClose4}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style1}>
              {loading1 ? (
                <Grid
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'60vh'}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  <Grid container justifyContent='flex-end'>
                    <IconButton
                      onClick={handleClose4}
                      sx={{ position: 'absolute', top: 5, right: 5 }}
                    >
                      <CloseIcon sx={{ color: '#000' }} />
                    </IconButton>
                  </Grid>
                  <Grid
                    container
                    justifyContent='center'
                    flexDirection={'column'}
                    textAlign='center'
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      position={'relative'}
                      height={'250px'}
                      width={'100%'}
                      mt={2}
                    >
                      {!isEditing ? (
                        <>
                          <img
                            src={uploadedFileURL || selectedRow?.category_img}
                            alt='Selected File'
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'cover',
                              borderRadius: '5px',
                            }}
                          />
                          <Tooltip
                            title='Change Image'
                            placement='bottom'
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: 'black',
                                  backgroundColor: '#e5ecfa',
                                  border: '1px solid black',
                                  fontSize: '0.7rem',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                  letterSpacing: '.5px',
                                  padding: 1,
                                },
                              },
                            }}
                          >
                            <ChangeCircleIcon
                              sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                cursor: 'pointer',
                                fontSize: '30px',
                              }}
                              onClick={handleIconClick}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <Box
                          sx={{
                            p: 5,
                            border: '2px dashed rgb(24, 44, 97)',
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            height: '100%',
                            width: '100%',
                          }}
                          onClick={() =>
                            document.getElementById('file-upload').click()
                          }
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                        >
                          <CloudUploadIcon
                            sx={{ color: 'rgb(24, 44, 97)', fontSize: 40 }}
                          />
                          <Typography
                            sx={{
                              mt: 1,
                              color: 'rgb(24, 44, 97)',
                              fontWeight: 'bold',
                            }}
                          >
                            Browse File To Upload
                          </Typography>
                          <input
                            id='file-upload'
                            type='file'
                            accept='image/*'
                            onChange={handleFileChangeInternal}
                            style={{ display: 'none' }}
                          />
                        </Box>
                      )}
                    </Box>

                    <TextField
                      label={'Category'}
                      variant='outlined'
                      value={Editcategory}
                      onChange={(e) => setEditCategory(e.target.value)}
                      fullWidth
                      sx={{
                        mt: 2,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '& fieldset': {
                            borderColor: '#e4ecfa',
                          },
                          '&:hover fieldset': {
                            borderColor: '#1c2d62',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#1c2d62',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: '#1c2d62',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#1c2d62',
                        },
                      }}
                    />
                    <Button
                      variant='contained'
                      onClick={() => handleEditcategorySave(params.row)}
                      sx={{
                        mt: 2,
                        backgroundColor: '#1c2d62',
                        color: '#fff',
                        borderRadius: '5px',
                        '&:hover': {
                          backgroundColor: '#115293',
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              )}
            </Box>
          </Modal>
          <Fab
            color='primary'
            aria-label='delete'
            size='small'
            sx={{ marginLeft: '10px' }}
            onClick={() => {
              handleDeletecategory(
                params.row.category_id,
                params.row.category_img
              );
            }}
          >
            <Delete
              sx={{
                color: 'white',
                cursor: 'pointer',
                fontSize: '18px',
              }}
            />
          </Fab>
        </>
      ),
    },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#F5F5F7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#DCE0E6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1C1C1C',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#F5F5F5' : '#fff',
    },
  }));

  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Section1
        content=''
        height={'70px'}
        slider={false}
        inputhide={'true'}
        mainMenuHidden={false}
      />
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginY: 2,
        }}
      >
        <Grid
          container
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'85%'}
        >
          <Grid
            item
            display={'flex'}
            justifyContent={'end'}
            alignItems={'center'}
            marginY={2}
            xs={12}
          >
            <button
              onClick={handleOpen1}
              style={{
                height: '30px',
                width: '100px',
                backgroundColor: '#182c61',
                borderRadius: '5px',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              {' '}
              Add Category
            </button>
          </Grid>
        </Grid>
        <Box
          sx={{
            overflowX: 'scroll',
            width: '90%',
            height: 400,
            minHeight: '70vh',
          }}
        >
          <DataGrid
            rows={categoryList}
            columns={column1}
            pageSize={10}
            getRowId={(row) => row.category_id}
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
            className='tableData'
            rowsPerPageOptions={[10, 20, 50]}
            initialState={{
              ...categoryList.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            page={page - 1}
            onPageChange={(newPage) => setPage(newPage + 1)}
            loading={loading}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: 'none',
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: 'none',
                },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#182c61',
                fontSize: '16px',
                textAlign: 'center',
                color: '#fff',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                textAlign: 'center',
              },
              '& .MuiDataGrid-sortIcon': {
                color: '#fff',
              },
              '& .MuiDataGrid-menuIconButton': {
                opacity: 1,
                color: 'white',
              },
            }}
          />
        </Box>
      </Box>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style1}>
          {loading1 ? (
            <Grid
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'60vh'}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid container justifyContent='flex-end'>
                <IconButton
                  onClick={handleClose1}
                  sx={{ position: 'absolute', top: 5, right: 5 }}
                >
                  <CloseIcon sx={{ color: '#000' }} />
                </IconButton>
              </Grid>
              <Grid
                container
                justifyContent='center'
                flexDirection={'column'}
                textAlign='center'
                mt={2}
              >
                <TextField
                  label='Category'
                  variant='outlined'
                  value={Category}
                  onChange={(e) => setCategory(e.target.value)}
                  fullWidth
                  sx={{
                    mb: 3, // Margin bottom to create space between the TextField and other elements
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      '& fieldset': {
                        borderColor: '#1c2d62',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1c2d62',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1c2d62',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#1c2d62',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#1c2d62',
                    },
                  }}
                />
                {file !== null ? (
                  <Box
                    sx={{
                      p: 6,
                      border: '2px dashed rgb(24, 44, 97)',
                      borderRadius: 1,

                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <Tooltip
                      title='Change Image'
                      placement='bottom'
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: 'black',
                            backgroundColor: '#e5ecfa',
                            border: '1px solid black',
                            fontSize: '0.7rem',
                            '& .MuiTooltip-arrow': {
                              color: 'black',
                            },
                            letterSpacing: '.5px',
                            padding: 1,
                          },
                        },
                      }}
                    >
                      <ChangeCircleIcon
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          cursor: 'pointer',
                          fontSize: '30px',
                        }}
                        onClick={handleIconClick1}
                      />
                    </Tooltip>

                    <Grid
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                    >
                      <img
                        src={fileURL}
                        alt='Selected File'
                        style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'cover',
                          marginBottom: '10px',
                          borderRadius: '5px',
                        }}
                      />
                      <Typography variant='body1' sx={{ minWidth: '180px' }}>
                        File Name: {file.name}
                      </Typography>
                      <Typography variant='body2'>
                        File Size: {convertBytesToMB(file.size)} MB
                      </Typography>
                    </Grid>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        p: 7,
                        border: '2px dashed rgb(24, 44, 97)',
                        borderRadius: 1,

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        document.getElementById('file-upload').click()
                      }
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <CloudUploadIcon
                        sx={{ color: 'rgb(24, 44, 97)', fontSize: 40 }}
                      />
                      <Typography
                        sx={{
                          mt: 2,
                          color: 'rgb(24, 44, 97)',
                          fontWeight: 'bold',
                        }}
                      >
                        Browse File To Upload
                      </Typography>
                      <input
                        id='file-upload'
                        type='file'
                        accept='image/*'
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </Box>
                  </>
                )}

                <Button
                  variant='contained'
                  onClick={handlecategorySave}
                  disabled={file === null || Category.length === 0}
                  sx={{
                    mt: 2,
                    backgroundColor: '#1c2d62',
                    color: '#fff',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#115293',
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </Grid>
  );
}
