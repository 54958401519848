import React, { useContext, useEffect, useRef, useState } from 'react';
import Section1 from '../Home/Section1';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  IconButton,
  Backdrop,
} from '@mui/material';
import Related_images from './Related_images';
import Tooltip from '@mui/material/Tooltip';
import { UserContext } from '../Context/List';
import {
  Download,
  imageDetails,
  Like_detail,
  show_tost,
} from '../../Utils/Helper';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Modal from '@mui/material/Modal';
import { CloseSharp } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

function Perticular_image(props) {
  const [scroll, setscroll] = useState(true);
  const [AllData, setAllData] = useState(null);
  const { Relatedimages } = useContext(UserContext);
  const { setRelatedimages } = useContext(UserContext);
  const [loadingImage, setLoadingImage] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const { Image } = useParams();
  const imageUrl = `/${Image}`;
  const imageUrl1 = `${Image}`;

  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingImage(true);
    imageDetails(
      window.location.pathname.split('/')[
        window.location.pathname.split('/').length - 1
      ]
    )
      .then((data) => {
        setAllData(data);
        setIsLiked(data.img_like);
        setRelatedimages(data);
        setLoadingImage(false);
      })
      .catch((error) => {
        console.error('Rejected', error);
        setLoadingImage(false);
      });
    if (token == null) {
      navigate('/signin');
    }
  }, [scroll, Image]);

  const handleLikeToggle = () => {
    const newLikeState = !isLiked;
    const isliked = { flag: newLikeState ? 1 : 0 };

    Like_detail(imageUrl1, isliked)
      .then((data) => {
        setIsLiked(newLikeState);

        sessionStorage.setItem(imageUrl1, newLikeState);
        window.scrollTo(0, 0);
        imageDetails(
          window.location.pathname.split('/')[
            window.location.pathname.split('/').length - 1
          ]
        )
          .then((data) => {
            setAllData(data);
            setIsLiked(data.img_like);
            setRelatedimages(data);
            setLoadingImage(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
          });
      })
      .catch((error) => {
        console.error('Rejected', error);
      });
  };
  const handleDownload = () => {
    const modifiedImageUrl = imageUrl.replace(/^\//, '');
    setOpen(true);
    Download(modifiedImageUrl)
      .then((data) => {
        const blob = new Blob([data], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = AllData && AllData.filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
        imageDetails(
          window.location.pathname.split('/')[
            window.location.pathname.split('/').length - 1
          ]
        )
          .then((data) => {
            setAllData(data);
            setIsLiked(data.img_like);
            setRelatedimages(data);
            setLoadingImage(false);
          })
          .catch((error) => {
            console.error('Rejected', error);
          });
        setOpen(false);
      })
      .catch((error) => {
        console.error('Rejected:', error);
        setOpen(false);
      });
  };
  function convertToTitleCase(str) {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const handleClose = () => {
    setOpen(false);
  };
  function cmToPx(cm) {
    const PPI = 96; // standard screen PPI
    const cmToInch = 2.54;
    return cm * (PPI / cmToInch);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const heightInCm = parseFloat(
    AllData && (AllData.height || '').replace(/[^0-9.]/g, '')
  );
  const heightInPx = cmToPx(heightInCm);
  const widthInCm = parseFloat(
    AllData && (AllData.width || '').replace(/[^0-9.]/g, '')
  );
  const widthInPx = cmToPx(widthInCm);
  const height = { xs: '240px', md: '200px', lg: '170px' };
  return (
    <Grid container>
      <Section1
        content='Old Rough Stone Matt Marble Floor Tiles - Perfect For Home Decor'
        height={height}
        inputhide='true'
        slider={false}
        mainMenuHidden={false}
      ></Section1>
      <Grid
        container
        mt={1}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {loadingImage ? (
          <Grid
            container
            mt={4}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            minHeight={'60vh'}
          >
            <CircularProgress></CircularProgress>
          </Grid>
        ) : (
          <>
            {AllData == null ? (
              <Grid
                xs={12}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                minHeight={'50vh'}
                marginY={2}
              >
                <img
                  src='/noimage.svg'
                  style={{
                    height: '350px',
                    width: '350px',
                  }}
                />
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  md={7.5}
                  xs={11}
                  display='flex'
                  flexDirection={'column'}
                  justifyContent='center'
                  alignItems='center'
                >
                  <Paper
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                      height: {
                        lg: '400px',
                        xs: '200px',
                        sm: '250px',
                        md: '350px',
                      },
                      width: { lg: '80%', xs: '70%', sm: '75%' },
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: 'none',
                      marginTop: '10px',
                      backgroundColor: '#eceeef',
                      position: 'relative',
                      border: '2px solid #f8fafb',
                      borderRadius: '10px',
                    }}
                  >
                    {/* {loadingImage ? (
                  <CircularProgress />
                ) : ( */}
                    <img
                      src={Relatedimages.file}
                      id='perticular_image'
                      alt='Selected'
                      style={{
                        width: '400px',
                        height: ' 85%',
                        objectFit: '100% 100%',
                        borderRadius: '5px',
                      }}
                      onLoad={() => setLoadingImage(false)}
                    />
                    <FullscreenIcon
                      onClick={handleOpen1}
                      sx={{
                        position: 'absolute',
                        top: 3,
                        right: 3,
                        cursor: 'pointer',
                      }}
                    ></FullscreenIcon>
                  </Paper>
                </Grid>
                <Grid
                  item
                  display='flex'
                  flexDirection={'column'}
                  justifyContent='center'
                  alignItems='center'
                  sx={{ paddingY: '0px' }}
                  md={4}
                  xs={11}
                >
                  <>
                    <Grid
                      container
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                      mt={2}
                      sx={{
                        border: '1px solid grey',
                        width: '90%',
                        borderRadius: '5px',
                        padding: '10px 5px',
                      }}
                    >
                      <Box
                        display='flex'
                        justifyContent={'space-around'}
                        alignItems='center'
                        mt={1}
                        sx={{
                          width: '100%',
                          padding: '10px 5px',
                          borderBottom: '1px solid #525252',
                        }}
                      >
                        <Grid
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems='center'
                          flexDirection={'column'}
                        >
                          <Typography>{AllData.total_views}</Typography>
                          <Typography>Views</Typography>
                        </Grid>

                        <Grid
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems='center'
                          flexDirection={'column'}
                        >
                          <Typography>{AllData.total_likes}</Typography>
                          <Typography>Likes</Typography>
                        </Grid>

                        <Grid
                          display={'flex'}
                          justifyContent={'space-around'}
                          alignItems='center'
                          flexDirection={'column'}
                        >
                          <Typography>{AllData.total_downloads}</Typography>
                          <Typography>Downloads</Typography>
                        </Grid>
                      </Box>
                      <Box
                        display='flex'
                        justifyContent={'space-around'}
                        alignItems='center'
                        sx={{
                          width: '100%',
                        }}
                        mt={2}
                      >
                        <IconButton onClick={handleDownload}>
                          <DownloadIcon sx={{ color: 'grey' }}></DownloadIcon>
                        </IconButton>
                        <Backdrop
                          sx={{
                            color: 'red',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={open}
                          onClick={handleClose}
                        >
                          <CircularProgress
                            className='homeloading'
                            sx={{
                              color: '#182c61',
                            }}
                          />
                        </Backdrop>

                        <IconButton onClick={handleLikeToggle}>
                          {isLiked ? (
                            <ThumbDownIcon sx={{ color: 'red' }} />
                          ) : (
                            <ThumbUpIcon sx={{ color: '#182c61' }} />
                          )}
                        </IconButton>
                      </Box>
                    </Grid>
                    <Box
                      display='flex'
                      mt={2}
                      alignItems='center'
                      justifyContent={'center'}
                      sx={{
                        border: '1px solid grey',
                        borderRadius: '5px',
                        width: '90%',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Typography
                        variant='h6'
                        mb={1}
                        sx={{
                          alignSelf: 'flex-start',
                          marginLeft: '17px',
                          fontFamily: 'Poppins,sans-serif',
                          color: '#525252',
                          fontWeight: 600,
                          fontSize: '16px',
                          width: '100%',
                        }}
                      >
                        {AllData && AllData.filename}
                      </Typography>
                      {AllData &&
                        Object.entries(AllData)
                          .slice(0, 1) // First four entries
                          .map(([key, value], index) => {
                            const categories = value; // or key, depending on your data structure
                            const categoriesString = Array.isArray(categories)
                              ? categories.join(',')
                              : categories;
                            return (
                              <Box
                                key={index}
                                display='flex'
                                justifyContent={'space-between'}
                                alignItems='center'
                                mb={1}
                                sx={{
                                  border: '1px solid grey',
                                  borderRadius: '5px',
                                  width: { xs: '97%', lg: '47%' },
                                  padding: '8px 4px',
                                  marginRight: '5px',
                                }}
                              >
                                <Typography
                                  variant='body2'
                                  sx={{
                                    fontSize: '14px',
                                    fontFamily: 'Poppins, sans-serif',
                                    color: '#525252',
                                  }}
                                >
                                  {convertToTitleCase(key)}
                                </Typography>
                                <Tooltip
                                  title={categoriesString}
                                  placement='top'
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        color: 'black',
                                        backgroundColor: '#e5ecfa',
                                        border: '1px solid black',
                                        fontSize: '0.7rem',
                                        '& .MuiTooltip-arrow': {
                                          color: 'black',
                                        },
                                        letterSpacing: '.5px',
                                        padding: 1,
                                      },
                                    },
                                  }}
                                >
                                  <Typography
                                    variant='body2'
                                    sx={{
                                      fontSize: '14px',
                                      fontFamily: 'Poppins, sans-serif',
                                      color: '#525252',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {typeof categoriesString === 'object'
                                      ? JSON.stringify(
                                          categoriesString.slice(0, 10) + '...'
                                        )
                                      : categoriesString.slice(0, 10) + '...'}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            );
                          })}

                      {AllData &&
                        Object.entries(AllData)
                          .slice(2, 4)
                          .map(([key, value], index) => (
                            <Box
                              key={index + 4} // Adjust key to avoid duplicates
                              display='flex'
                              justifyContent={'space-between'}
                              alignItems='center'
                              mb={1}
                              sx={{
                                border: '1px solid grey',
                                borderRadius: '5px',
                                width: { xs: '97%', lg: '47%' },

                                padding: '8px 4px',
                                marginRight: '5px',
                              }}
                            >
                              <Typography
                                variant='body2'
                                sx={{
                                  fontSize: '14px',
                                  fontFamily: 'Poppins, sans-serif',
                                  color: '#525252',
                                }}
                              >
                                {convertToTitleCase(key)}
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{
                                  fontSize: '14px',
                                  fontFamily: 'Poppins, sans-serif',
                                  color: '#525252',
                                }}
                              >
                                {/* {typeof value === 'object'
                                  ? JSON.stringify(value)
                                  : value} */}
                                TIF
                              </Typography>
                            </Box>
                          ))}

                      <Box
                        display='flex'
                        justifyContent={'space-between'}
                        alignItems='center'
                        mb={1}
                        sx={{
                          border: '1px solid grey',
                          borderRadius: '5px',
                          width: { xs: '97%', lg: '47%' },

                          padding: '8px 4px',
                          marginRight: '5px',
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'Poppins, sans-serif',
                            color: '#525252',
                          }}
                        >
                          Height
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'Poppins, sans-serif',
                            color: '#525252',
                          }}
                        >
                          {AllData && AllData.height}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        justifyContent={'space-between'}
                        alignItems='center'
                        mb={1}
                        sx={{
                          border: '1px solid grey',
                          borderRadius: '5px',
                          width: { xs: '97%', lg: '47%' },

                          padding: '8px 4px',
                          marginRight: '5px',
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'Poppins, sans-serif',
                            color: '#525252',
                          }}
                        >
                          Width
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'Poppins, sans-serif',
                            color: '#525252',
                          }}
                        >
                          {AllData && AllData.width}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </Grid>
                <Related_images
                  setscroll={setscroll}
                  scroll={scroll}
                ></Related_images>
                <Modal
                  open={open1}
                  onClose={handleClose1}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box
                    sx={style}
                    onClick={() => {
                      setOpen1(false);
                    }}
                  >
                    <img
                      src={Relatedimages.file}
                      id='perticular_image'
                      ref={fileInputRef}
                      alt='Selected'
                      style={{
                        width: { xs: '80%', sm: '70%', md: '40%' },
                        height: ' 90%',
                        objectFit: '100% 100%',
                        borderRadius: '5px',
                      }}
                      onLoad={() => setLoadingImage(false)}
                    />
                    <CloseSharp
                      sx={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        cursor: 'pointer',
                        color: 'white',
                        fontSize: '30px',
                        fontWeight: 'bold',
                      }}
                    ></CloseSharp>
                  </Box>
                </Modal>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Perticular_image;
