// NotFound.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import '../../css/notfound.css';
import { Grid, Typography } from '@mui/material';
import Section1 from '../Home/Section1';
import Photobtn from '../Common/Photobtn';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      flexWrap='nowrap'
      style={{ height: '70vh', textAlign: 'center' }}
    >
      <Section1
        content=''
        height='60px'
        inputhide='true'
        slider={false}
        mainMenuHidden={false}
      />
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        style={{ height: '100%' }}
      >
        <img
          src='/404error.svg'
          alt='Not Found'
          style={{
            width: '100%',
            maxWidth: '400px',
            maxHeight: '280px',
            margin: '20px 0',
          }}
        />
        <Photobtn onClick={() => navigate('/')} label='Back to Home' />
      </Grid>
    </Grid>
  );
};

export default NotFound;
