import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import { UserContext } from '../Context/List';

function Allsection(props) {
  const { category_detail } = useContext(UserContext);

  let height;

  if (category_detail.length > 2) {
    if (category_detail.length == 1 && category_detail[0].category == 'ALL') {
      height = '400px';
    } else {
      height = '600px';
    }
  } else {
    height = '400px';
  }

  return (
    <Grid>
      <Grid>
        <Section1
          content=' The best premium photos of marble, ceramic tiles, laminate, and more.'
          height={height}
          slider={true}
          mainMenuHidden={false}
        ></Section1>
      </Grid>
      <Grid>
        <Section2 />
      </Grid>
    </Grid>
  );
}

export default Allsection;
