import React, { useEffect, useState } from 'react';
import { Grid, Typography, Pagination, Box } from '@mui/material';
import '../../css/commonbtn.css';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { AllImages } from '../../Utils/Helper';
import Dotwave from '../Common/Dotwave';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';

function All(props) {
  const navigate = useNavigate();

  const handleimage = (src) => {
    navigate(`/Image_detail/${src}`);
  };

  const handlePageChange = (event, value) => {
    props.setactivepage(value);
    window.scrollTo(0, 0);
  };
  return (
    <>
      {props.loading ? (
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          // mt={5}
          minHeight={'50vh'}
        >
          <Dotwave></Dotwave>
        </Grid>
      ) : (
        <>
          {props.noImages ? (
            <Grid
              xs={12}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={'50vh'}
              marginY={2}
            >
              <img
                src='/noimage.svg'
                style={{
                  height: '350px',
                  width: '350px',
                }}
              />
            </Grid>
          ) : (
            <>
              <>
                {/* <Grid
                  container
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  mt={2}
                >
                  {props.images.map((d, i) => (
                    <Grid
                      key={i}
                      item
                      height={200}
                      width={200}
                      m={2}
                      spacing={2}
                      sx={{
                        borderRadius: '5px',
                        position: 'relative',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover .overlay': {
                          opacity: 1,
                        },
                        '&:hover .typography-container': {
                          marginTop: '150px',
                        },
                      }}
                      onClick={() => handleimage(d.img_id)}
                    >
                      <img
                        src={d.image_url}
                        style={{
                          height: 'inherit',
                          width: '200px',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          borderRadius: '5px',
                        }}
                      />

                      <Grid
                        className='overlay'
                        sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          height: 'inherit',
                          width: 'inherit',
                          backgroundColor: 'rgba(0,0,0,0.4)',
                          zIndex: 0,
                          opacity: 0,
                          transition: 'all 0.3s ease',
                          borderRadius: '5px',
                        }}
                      >
                        <Grid
                          className='typography-container'
                          marginTop={'190px'}
                          sx={{
                            transition: 'margin-top 0.4s ease',
                          }}
                        >
                          <Tooltip
                            title={d.filename}
                            placement='top'
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: 'black',
                                  backgroundColor: '#e5ecfa',
                                  border: '1px solid black',
                                  fontSize: '0.7rem',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                  letterSpacing: '.5px',
                                  padding: 1,
                                },
                              },
                            }}
                          >
                            <Typography
                              variant='p'
                              sx={{
                                color: 'white',
                                fontSize: '14px',
                                marginLeft: '10px',
                              }}
                            >
                              {d.filename.slice(0, 10) + '...'}
                            </Typography>
                          </Tooltip>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            sx={{ marginTop: '5px' }}
                          >
                            <FavoriteBorderRoundedIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_likes}
                            </Typography>
                            <DownloadIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_download}
                            </Typography>
                            <VisibilityIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_view}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid> */}
                  <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
      mt={2}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '4px',

          width: '99%',
        }}
      >
        {props.images.map((d, i) => (
          <Box
            key={i}
            sx={{
              position: 'relative',
              height: '200px',
              flex: '1 1 auto',
              maxWidth: '500px',
              overflow: 'hidden',
              minWidth: '200px',
              cursor:'pointer',
              marginBottom: 0,
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover .overlay': {
                opacity: 1,
              },
              '&:hover .typography-container': {
                marginTop: '150px',
              },
            }}
            onClick={() => handleimage(d.img_id)}
          >
            <img
              src={d.image_url}
              alt={d.title}
              style={{
                height: '100%',

                width: '100%',
                objectFit: 'cover',
                display: 'block',
              }}
            />
            <Grid
                        className='overlay'
                        sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          height: '100%',
                          width: '100%',
                          backgroundColor: 'rgba(0,0,0,0.4)',
                          zIndex: 0,
                          opacity: 0,
                          transition: 'all 0.3s ease',
                          borderRadius: '5px',
                        }}
                      >
                       
                        <Grid
                          className='typography-container'
                          marginTop={'190px'}
                          sx={{
                            transition: 'margin-top 0.4s ease',
                          }}
                        >
                          <Tooltip
                            title={d.filename}
                            placement='top'
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: 'black',
                                  backgroundColor: '#e5ecfa',
                                  border: '1px solid black',
                                  fontSize: '0.7rem',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                  letterSpacing: '.5px',
                                  padding: 1,
                                },
                              },
                            }}
                          >
                            <Typography
                              variant='p'
                              sx={{
                                color: 'white',
                                fontSize: '14px',
                                marginLeft: '10px',
                              }}
                            >
                              {d.filename.slice(0, 10) + '...'}
                            </Typography>
                          </Tooltip>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            sx={{ marginTop: '5px' }}
                          >
                            <FavoriteBorderRoundedIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_likes}
                            </Typography>
                            <DownloadIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_download}
                            </Typography>
                            <VisibilityIcon
                              sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white',
                                marginLeft: '3px',
                                fontSize: '12px',
                              }}
                            >
                              {d.total_view}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
          </Box>
        ))}
      </Box>
    </Box>
                <Grid container justifyContent={'center'} mt={4} mb={2}>
                  <Pagination
                    count={props.pagination}
                    page={props.activepage}
                    onChange={handlePageChange}
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'rgba(0, 0, 0, 0.54)',
                      },
                      '& .Mui-selected': {
                        backgroundColor: '#e4ecfa !important',
                        color: 'rgba(0,0,0,0.54) !important',
                      },
                      '& .MuiPaginationItem-ellipsis': {
                        color: '#e4ecfa',
                      },
                    }}
                  />
                </Grid>
              </>
            </>
          )}
        </>
      )}
    </>
  );
}

export default All;
