import React, { createContext, useEffect, useState } from 'react';

import { GET_USERS } from '../../Utils/endpoint';
import { auth_get } from '../../Utils/Request';
import { Category_type } from '../../Utils/Helper';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [images, setImages] = useState([]);
  const [noImages, setnoImages] = useState(false);
  const [Latest, setLatest] = useState([]);
  const [Role, setRole] = useState(null);
  const [category_detail, setcategory_detail] = useState([]);
  const [Imagedetail, setImagedetail] = useState([]);
  const [Relatedimages, setRelatedimages] = useState([]);
  const [SearchtextList, setSearchtextList] = useState([]);
  const [inputlabel, setinputlabel] = useState(null);
  const [isliked, setIsLiked] = useState(false);
  const [userList, setuserList] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [dropdownselest, setdropdownselest] = useState('ALL');
  const [searchTerm, setSearchTerm] = useState('');
  const [infinity, setinfinity] = useState(true);
  const [searchImage, setsearchImage] = useState([]);
  const [allImages, setallImages] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [searchList, setSearchList] = useState([]);

  const usertoken = sessionStorage.getItem('token');

  useEffect(() => {
    if (usertoken) {
      auth_get(
        GET_USERS,
        (data) => {
          setRole(data.role);
        },
        (error) => {
          console.error('Error fetching user data:', error);
        }
      );
    }
  }, [usertoken]);

  // useEffect(() => {
  //   Category_type()
  //     .then((data) => {
  //       setcategory_detail(data);
  //     })
  //     .catch((error) => {
  //       console.error('Rejected:', error);
  //     });
  // }, [setcategory_detail]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        images,
        setImages,
        Latest,
        setLatest,
        Role,
        setRole,
        category_detail,
        setcategory_detail,
        Imagedetail,
        setImagedetail,
        Relatedimages,
        setRelatedimages,
        SearchtextList,
        setSearchtextList,
        inputlabel,
        setinputlabel,
        dropdownselest,
        setdropdownselest,
        isliked,
        setIsLiked,
        userList,
        setuserList,
        listCategory,
        setListCategory,
        noImages,
        setnoImages,
        infinity,
        setinfinity,
        searchImage,
        setsearchImage,
        allImages,
        setallImages,
        refresh,
        setrefresh,
        searchList,
        setSearchList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
