import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Grid, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ButtonBorder from '../Common/ButtonBorder';

import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Popper from '@mui/material/Popper';
import { UserContext } from '../Context/List';
import UploadFile from '@mui/icons-material/UploadFile';
import StorageIcon from '@mui/icons-material/Storage';
import PhotoIcon from '@mui/icons-material/Photo';
import WidgetsIcon from '@mui/icons-material/Widgets';

const Navbar = ({ menuHidden }) => {
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 900px)');
  const [logout, setLogout] = useState(
    sessionStorage.getItem('name') ? true : false
  );
  const [BtnOpen, setBtnOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigation = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const { Role } = useContext(UserContext);
  const navigate = useNavigate();
  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open1 = Boolean(anchorEl);
  const id = open1 ? 'simple-popper' : undefined;

  const toggleDrawer = () => {
    setOpen(!open && isSmallScreen);
  };

  const handleopensignin = () => {
    navigation('/signin');
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (open1) {
        e.preventDefault();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open1]);

  const fullName = sessionStorage.getItem('name') || '';
  const truncatedName =
    fullName.length > 5 ? `${fullName.substring(0, 5)}...` : fullName;

  const DrawerList = (
    <Grid
      container
      position={'relative'}
      sx={{
        width: 250,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: '20px',
        height: '100%',
      }}
      role='presentation'
      onClick={toggleDrawer}
    >
      <Grid item sx={{ textAlign: 'center' }}>
        {logout ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              backgroundColor: 'black',
              color: 'white',
              padding: '2px 0px',
              borderRadius: '20px',
              width: '100px',
              marginLeft: '130px',
            }}
          >
            <Typography
              variant='h6'
              sx={{ color: 'white', marginRight: '5px', fontSize: '18px' }}
            >
              {truncatedName}
            </Typography>
            <AccountCircleIcon color='grey'></AccountCircleIcon>
          </Box>
        ) : (
          <img src='/header_dark.png' height={50} width={230} alt='Logo' />
        )}
      </Grid>
      <Divider />
      <Grid item>
        <Grid
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          color={'#150f0fc2'}
          mt={0.3}
        >
          <Box
            sx={{
              width: '90%',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e4ecfa',
                borderRadius: '5px',
              },
            }}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            onClick={() => {
              navigate('/');
            }}
          >
            <HomeIcon
              sx={{ fontSize: 18, marginLeft: '10px', fontWeight: 'bold' }}
            />
            <Typography
              variant='h6'
              sx={{ cursor: 'pointer', marginLeft: '10px' }}
            >
              <Link
                to='/'
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  fontSize: 17,
                  fontWeight: 'bold',
                }}
              >
                Home
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          color={'#150f0fc2'}
          mt={0.2}
        >
          <Box
            sx={{
              width: '90%',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e4ecfa',
                borderRadius: '5px',
              },
            }}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            onClick={() => {
              navigate('/photos');
            }}
          >
            <PhotoLibraryIcon
              sx={{ fontSize: 18, marginLeft: '10px', fontWeight: 'bold' }}
            />
            <Typography
              variant='h6'
              sx={{ cursor: 'pointer', marginLeft: '10px' }}
            >
              <Link
                to='/photos'
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  fontSize: 17,
                  fontWeight: 'bold',
                }}
              >
                Photos
              </Link>
            </Typography>
          </Box>
        </Grid>
        {logout && Role === 'superuser' && (
          <>
            <Grid
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'#150f0fc2'}
              mt={0.2}
            >
              <Box
                sx={{
                  width: '90%',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#e4ecfa',
                    borderRadius: '5px',
                  },
                }}
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                onClick={() => {
                  navigate('/category');
                }}
              >
                <WidgetsIcon
                  sx={{ fontSize: 18, marginLeft: '10px', fontWeight: 'bold' }}
                />
                <Typography
                  variant='h6'
                  sx={{ cursor: 'pointer', marginLeft: '10px' }}
                >
                  <Link
                    to='/category'
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontSize: 17,
                      fontWeight: 'bold',
                    }}
                  >
                    Category
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'#150f0fc2'}
              mt={0.2}
            >
              <Box
                sx={{
                  width: '90%',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#e4ecfa',
                    borderRadius: '5px',
                  },
                }}
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                onClick={() => {
                  navigate('/allImages');
                }}
              >
                <PhotoIcon
                  sx={{ fontSize: 18, marginLeft: '10px', fontWeight: 'bold' }}
                />
                <Typography
                  variant='h6'
                  sx={{ cursor: 'pointer', marginLeft: '10px' }}
                >
                  <Link
                    to='/allImages'
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontSize: 17,
                      fontWeight: 'bold',
                    }}
                  >
                    Images
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'#150f0fc2'}
              mt={0.2}
            >
              <Box
                sx={{
                  width: '90%',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#e4ecfa',
                    borderRadius: '5px',
                  },
                }}
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                onClick={() => {
                  navigate('/users');
                }}
              >
                <StorageIcon
                  sx={{ fontSize: 18, marginLeft: '10px', fontWeight: 'bold' }}
                />
                <Typography
                  variant='h6'
                  sx={{ cursor: 'pointer', marginLeft: '10px' }}
                >
                  <Link
                    to='/users'
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontSize: 17,
                      fontWeight: 'bold',
                    }}
                  >
                    Users
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      {logout ? (
        ''
      ) : (
        <Grid
          item
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: 250,
            position: 'absolute',
            top: '90%',
            left: 0,
          }}
        >
          {location.pathname !== '/signin' && (
            <ButtonBorder
              onClickButton1={handleopensignin}
              labelButton1='Sign In'
            ></ButtonBorder>
          )}
          {/* <BgButton
            onClickButton2={handleopensignup}
            labelButton2='Sign Up'
          ></BgButton> */}
        </Grid>
      )}
    </Grid>
  );

  return (
    <AppBar
      position='absolute'
      sx={{
        background: 'transparent',
        top: '5px',
        left: '0px',
        boxShadow: 'none',
        zIndex: 1,
      }}
    >
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid
          item
          xs={4}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <Box
            onClick={toggleDrawer}
            sx={{
              border: '1px solid white',
              padding: '3px 5px',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <MenuIcon sx={{}}></MenuIcon>
          </Box>
        </Grid>
        {location.pathname !== '/signin' ? (
          <Grid
            item
            md={4}
            xs={4}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
              justifyContent: { md: 'flex-start', xs: 'center' },
              padding: '5px 0 0 15px',
            }}
          >
            <img
              src='/Logo.png'
              height={45}
              width={220}
              alt='Logo'
              onClick={() => {
                navigate('/');
              }}
            />
          </Grid>
        ) : (
          <Grid
            item
            md={12}
            xs={4}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
              marginTop: '10px',
              justifyContent: { md: 'center', xs: 'center' },
            }}
          >
            <img
              src='/Logo.png'
              height={50}
              width={230}
              alt='Logo'
              onClick={() => {
                navigate('/');
              }}
            />
          </Grid>
        )}

        {menuHidden ? (
          ''
        ) : (
          <Grid
            item
            md={4}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              display: { md: 'flex', xs: 'none' },
            }}
          >
            <Link
              to='/'
              style={{
                textDecoration: 'none',
                color: activeLink === '/' ? '#9bb8f8' : 'inherit',
                marginRight: '15px',
              }}
              onClick={() => handleLinkClick('/')}
            >
              Home
            </Link>
            <Link
              to='/photos'
              style={{
                textDecoration: 'none',
                color: activeLink === '/photos' ? '#9bb8f8' : 'inherit',
                marginRight: '15px',
              }}
              onClick={() => handleLinkClick('/photos')}
            >
              Photos
            </Link>

            {logout && Role === 'superuser' && (
              <>
                <Link
                  to='/category'
                  style={{
                    textDecoration: 'none',
                    marginRight: '15px',
                    color: activeLink === '/category' ? '#9bb8f8' : 'inherit',
                  }}
                  onClick={() => handleLinkClick('/category')}
                >
                  Category
                </Link>
                <Link
                  to='/allImages'
                  style={{
                    textDecoration: 'none',
                    marginRight: '15px',
                    color: activeLink === '/allImages' ? '#9bb8f8' : 'inherit',
                  }}
                  onClick={() => handleLinkClick('/allImages')}
                >
                  Images
                </Link>
                <Link
                  to='/users'
                  style={{
                    textDecoration: 'none',
                    color: activeLink === '/users' ? '#9bb8f8' : 'inherit',
                    marginRight: '15px',
                  }}
                  onClick={() => handleLinkClick('/users')}
                >
                  Users
                </Link>
              </>
            )}
          </Grid>
        )}
        <Grid
          item
          md={4}
          xs={4}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          {logout ? (
            <>
              <Box
                aria-describedby={id}
                variant='contained'
                onClick={handleClick}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.3)',
                  color: 'grey',
                  padding: '3px 10px',
                  borderRadius: '20px',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
              >
                <AccountCircleIcon
                  sx={{ color: 'white', marginRight: '5px' }}
                ></AccountCircleIcon>
                <Typography
                  variant='h6'
                  sx={{
                    color: 'white',
                    // marginRight: '5px',
                    fontSize: '17px',
                    display: { xs: 'none', md: 'block' },
                  }}
                >
                  {sessionStorage.getItem('name')}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {BtnOpen ? (
                ''
              ) : (
                <>
                  <Grid
                    item
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ display: { md: 'flex', xs: 'none' } }}
                  >
                    {location.pathname !== '/signin' && (
                      <ButtonBorder
                        onClickButton1={handleopensignin}
                        labelButton1='Sign In'
                      ></ButtonBorder>
                    )}
                    {/* <BgButton
                      onClickButton2={handleopensignup}
                      labelButton2='Sign Up'
                    ></BgButton> */}
                  </Grid>
                  <Box
                    aria-describedby={id}
                    variant='contained'
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      backgroundColor: 'white',
                      color: 'grey',
                      padding: '5px 7px',
                      borderRadius: '20px',
                      marginRight: '10px',
                      display: { md: 'none', xs: 'flex' },
                    }}
                  >
                    <AccountCircleIcon color='grey'></AccountCircleIcon>
                  </Box>
                </>
              )}
            </>
          )}
        </Grid>
        <Drawer
          anchor='left'
          open={open}
          onClose={toggleDrawer}
          sx={{ '& .MuiDrawer-paper': { width: 250, left: 0 } }}
        >
          {DrawerList}
        </Drawer>
        <Popper id={id} open={open1} anchorEl={anchorEl} onClose={handleClose}>
          <Box
            sx={{
              backgroundColor: '#e5ecfa',
              padding: '5px 7px',
              fontWeight: 500,
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => {
              sessionStorage.clear();
              setAnchorEl(null);
              setLogout(false);
              window.history.replaceState(null, '', '/');
              window.location.reload();
            }}
          >
            Log Out
          </Box>
        </Popper>

        {/* <Popover
          id={id}
          open={open1}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            backgroundColor: 'transparent',
            minWidth: 'auto',
            maxWidth: 'none',
          }}
        >
          <Typography
            sx={{ padding: '8px', cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.clear();
              setAnchorEl(null);
              setLogout(false);
              window.history.replaceState(null, '', '/');
              window.location.reload();
            }}
          >
            Log Out
          </Typography>
        </Popover> */}
      </Grid>
    </AppBar>
  );
};

export default Navbar;
