import React, { useContext, useState } from 'react';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import ButtonBorder from '../Common/ButtonBorder';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { UserContext } from '../Context/List';
import Dotwave from '../Common/Dotwave';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import Dotwave1 from '../Common/Dotwave1';

function Related_images(props) {
  const navigate = useNavigate();
  const initialImagesCount = 10;
  const [imagesCount, setImagesCount] = useState(initialImagesCount);
  const { Relatedimages } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showbtn, setshowbtn] = useState(false);

  const handleImageClick = (src) => {
    props.setscroll(!props.scroll);

    navigate(`/Image_detail/${src}`);
  };

  const handleViewMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (imagesCount + 10 <= Relatedimages.related_img.length) {
        setImagesCount((prevCount) => prevCount + 10);
      } else {
        setshowbtn(true);
      }
      setIsLoading(false);
    }, 2000);
  };
  return (
    <Grid container spacing={2} mt={1} textAlign={'center'}>
      <Grid
        item
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          width: '100%',
        }}
      >
        <Typography
          variant='h5'
          sx={{
            fontSize: '30px',
            fontFamily: 'Poppins,sans-serif',
            color: '#525252',
            fontWeight: 'bold',
          }}
        >
          Related Photos
        </Typography>
      </Grid>
      {Relatedimages.related_img != undefined ? (
        <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          padding:'40px'
        }}
        mt={2}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
  
            width: '99%',
          }}
        >
          {Relatedimages.related_img.slice(0, imagesCount).map((d, i) => (
            <>
               <Box
            key={i}
            sx={{
              position: 'relative',
              height: '200px',
              flex: '1 1 auto',
              maxWidth: '500px',
              overflow: 'hidden',
              minWidth: '200px',
              cursor:'pointer',
              marginBottom: 0,
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover .overlay': {
                opacity: 1,
              },
              '&:hover .typography-container': {
                marginTop: '150px',
              },
            }}
            onClick={() => handleImageClick(d.img_id)}
          >
                <img
                  src={d.Image}
                  style={{
                    height: '100%',

                    width: '100%',
                    objectFit: 'cover',
                    display: 'block',
                  }}
                />

                <Grid
                  className='overlay'
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    zIndex: 0,
                    opacity: 0,
                    transition: 'all 0.3s ease',
                    borderRadius: '5px',
                  }}
                >
                  <Grid
                    className='typography-container'
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    flexDirection={'column'}
                    marginTop={'190px'}
                    sx={{
                      transition: 'margin-top 0.4s ease',
                    }}
                  >
                    <Tooltip
                      title={d.filename}
                      placement='top'
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: 'black',
                            backgroundColor: '#e5ecfa',
                            border: '1px solid black',
                            fontSize: '0.7rem',
                            '& .MuiTooltip-arrow': {
                              color: 'black',
                            },
                            letterSpacing: '.5px',
                            padding: 1,
                          },
                        },
                      }}
                    >
                      <Typography
                        variant='p'
                        sx={{
                          color: 'white',
                          fontSize: '14px',
                          marginLeft: '10px',
                        }}
                      >
                        {d.filename.slice(0, 10) + '...'}
                      </Typography>
                    </Tooltip>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      sx={{ marginTop: '5px' }}
                    >
                      <FavoriteBorderRoundedIcon
                        sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                      />
                      <Typography
                        variant='body2'
                        sx={{
                          color: 'white',
                          marginLeft: '3px',
                          fontSize: '12px',
                        }}
                      >
                        {d.total_likes}
                      </Typography>
                      <DownloadIcon
                        sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                      />
                      <Typography
                        variant='body2'
                        sx={{
                          color: 'white',
                          marginLeft: '3px',
                          fontSize: '12px',
                        }}
                      >
                        {d.total_download}
                      </Typography>
                      <VisibilityIcon
                        sx={{ color: 'white', ml: 1, fontSize: '17px' }}
                      />
                      <Typography
                        variant='body2'
                        sx={{
                          color: 'white',
                          marginLeft: '3px',
                          fontSize: '12px',
                        }}
                      >
                        {d.total_view}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          ))}
        </Box>
        </Box>
      ) : (
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={4}
          mb={4}
        >
          <Dotwave></Dotwave>
        </Grid>
      )}

      {Relatedimages.related_img && Relatedimages.related_img.length > 0 && (
        <Grid item xs={12} mb={2}>
          {isLoading ? ( // Show loader if isLoading is true
            <Dotwave1></Dotwave1>
          ) : (
            <>
              {showbtn ? (
                <></>
              ) : (
                <>
                  <ButtonBorder
                    labelButton1='View More'
                    onClickButton1={handleViewMore}
                  />
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default Related_images;
