import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

function Footer() {
  const location = useLocation();
  return (
    <>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ backgroundColor: '#182C61' }}
      >
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          sx={{ width: '90%' }}
        >
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            paddingY={3}
          >
            <Typography
              variant='h6'
              sx={{
                textAlign: 'center',
                fontWeight: 550,
                fontSize: '16px',

                color: '#e5ecfa',
              }}
            >
              Unique Marbel
            </Typography>
            <Typography
              variant='p'
              sx={{
                color: '#ddd',

                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                marginTop: '7px',
              }}
            >
              Get access to the pictures shared by our incredibly brilliant
              artists and come and join our massive community. Earn, contribute,
              and become one of our most talented creators.
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirection={'column'}
            paddingY={3}
          >
            <Typography
              variant='h6'
              sx={{
                textAlign: 'center',
                fontWeight: 550,
                fontSize: '16px',

                color: '#e5ecfa',
              }}
            >
              Find Us On
            </Typography>
            <Box
              display={'flex'}
              justifyContent={'center'}
              sx={{
                color: 'white',
                marginTop: '7px',
              }}
            >
              <FacebookIcon
                sx={{
                  cursor: 'pointer',
                  marginRight: '7px',
                  fontSize: '22px',
                }}
              ></FacebookIcon>
              <InstagramIcon
                sx={{
                  fontSize: '22px',
                  cursor: 'pointer',
                }}
              ></InstagramIcon>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            paddingY={3}
          >
            <Typography
              variant='h6'
              sx={{
                textAlign: 'center',
                fontWeight: 550,
                color: '#e5ecfa',
                fontSize: '16px',
              }}
            >
              Support
            </Typography>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              sx={{
                color: 'white',
                marginTop: '7px',
                marginLeft: '-66px',
              }}
            >
              <PhoneIcon
                sx={{
                  marginRight: '7px',
                  fontSize: '22px',
                  cursor: 'pointer',
                }}
              ></PhoneIcon>
              <Typography
                variant='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                123 345 5678
              </Typography>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              sx={{
                color: 'white',
                marginTop: '5px',
              }}
            >
              <EmailIcon
                sx={{
                  fontSize: '22px',
                  marginRight: '7px',
                  cursor: 'pointer',
                }}
              ></EmailIcon>
              <Typography
                variant='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                info@uniquemarbel.com
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
